import React from 'react';
import styled from 'styled-components';
import { above, below } from '../styles';
import { Container, Paragraph, FluidImg } from '../elements';
import checkPropsEquality from '../utils/checkPropsEquality';

const ContentWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin-top: ${props => (props.marginTopDesktop ? '250px' : 0)};
  margin-bottom: ${props => (props.marginBottomDesktop ? '170px' : 0)};
  @media ${below.md} {
    width: 100%;
    margin-top: 100px;
    margin-bottom: 70px;
    flex-direction: ${props =>
      props.textPosition === 'left' ? 'column-reverse' : 'column'};
  }
`;

const ImageWrapper = styled.div`
  width: 50%;
  margin-top: 12px;
  padding-left: 10%;
  padding-right: 14%;
  @media ${below.ld} {
    padding-right: 10%;
  }
  @media ${below.md} {
    width: 100%;
    margin-top: 0px;
    margin-${props => (props.textPosition === 'left' ? 'top' : 'bottom')}: 70px;
    padding-right: 0px;
    padding-left: 0px;
  }
`;

const TextWrapper = styled.div`
  margin-left: ${props => (props.textPosition === 'left' ? '0' : 'auto')};
  width: 48%;
  @media ${above.lg} {
    min-width: 500px;
  }
  @media ${below.md} {
    width: 100%;
  }
`;

const Heading = styled.h2`
  font-size: 5.2rem;
  line-height: 1.26;
  margin-bottom: 20px;
  @media ${above.lg} {
    font-size: 7.5rem;
    lene-height: 1.25em;
  }
`;

const ImageText = ({
  block: {
    heading,
    paragraph,
    image,
    textHorizontalPosition,
    marginTopDesktop,
    marginBottomDesktop,
  },
}) => (
  <Container>
    <ContentWrapper
      marginTopDesktop={marginTopDesktop}
      marginBottomDesktop={marginBottomDesktop}
      textPosition={textHorizontalPosition}
    >
      {image ? (
        <ImageWrapper textPosition={textHorizontalPosition}>
          <FluidImg
            asset={image.asset}
            alt={image.alt}
            imgStyle={{
              alignSelf: 'center',
              height: '100%',
            }}
            wrapperStyle={{
              maxWidth: '450px',
              margin: '0 auto',
            }}
          />
        </ImageWrapper>
      ) : (
        <div />
      )}
      <TextWrapper textPosition={textHorizontalPosition}>
        <Heading>{heading}</Heading>
        <Paragraph text={paragraph} />
      </TextWrapper>
    </ContentWrapper>
  </Container>
);

export default React.memo(ImageText, checkPropsEquality);
