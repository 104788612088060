import React from 'react';
import styled from 'styled-components';
import { CTALink, Container } from '../elements';
import BlockRenderer from './BlockRenderer';

import { above } from '../styles';

const Wrapper = styled.div`
  padding-top: ${props => !props.nestedGrid && '8px'};
  padding-bottom: ${props => !props.nestedGrid && '8px'};

  @media ${above.md} {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: ${props =>
      props.justifyCenter ? 'center' : 'space-between'};
    margin-top: ${({ noTopSpacing }) => (noTopSpacing ? 0 : '140px')};
    margin-bottom: ${({ noBottomSpacing }) => (noBottomSpacing ? 0 : '140px')};
    ${props => props.noTopSpacing && 'padding-top: 0'};
    ${props => props.noBottomSpacing && 'padding-bottom: 0'};
  }
`;

const LeftContent = styled.div`
  width: 100%;

  @media ${above.md} {
    position: sticky;
    top: 60px;
    width: ${props => (props.evencol ? '48%' : props.large ? '56%' : '37%')};
  }

  @media ${above.lg} {
  }
`;

const RightContent = styled.div`
  width: 100%;
  margin-top: 70px;
  margin-bottom: 70px;
  position: sticky;
  top: 60px;

  @media ${above.md} {
    margin-bottom: 0;
    margin-top: 0;
    width: ${props => (props.evencol ? '48%' : props.large ? '56%' : '37%')};
  }

  @media ${above.lg} {
    //margin-top: 0;
    //width: ${props => (props.evencol ? '48%' : props.large ? '56%' : '37%')};
  }
`;

const LinkWrapper = styled.div``

const TwoColumnGrid = ({ block, nestedGrid }) => {
  const {
    isLargeRightCol = false,
    leftContent,
    rightContent,
    fullWidth,
    isEvenCol,
    noTopSpacing,
    noBottomSpacing,
    justifyCenter,
    ctaUrlTextLeft,
    ctaUrlInternalLeft,
    ctaUrlExternalLeft,
    ctaUrlTextRight,
    ctaUrlInternalRight,
    ctaUrlExternalRight,
  } = block;

  const Component = fullWidth ? 'div' : Container;

  return (
    <Wrapper
      nestedGrid={nestedGrid}
      as={Component}
      noTopSpacing={noTopSpacing}
      noBottomSpacing={noBottomSpacing}
      justifyCenter={justifyCenter}
    >
      <LeftContent large={!isLargeRightCol} evencol={isEvenCol}>
        {leftContent &&
          leftContent.map(content => (
            <BlockRenderer
              nested={nestedGrid}
              key={content._key}
              block={content}
            />
          ))}
           <LinkWrapper>
          {ctaUrlExternalLeft ? (
          <CTALink
            center
            darkMode
            url={ctaUrlExternalLeft}
            text={ctaUrlTextLeft}
                      />
          ) : ctaUrlInternalLeft && (
          <CTALink
            center
            darkMode
            url={'/' + ctaUrlInternalLeft[0].slug.current}
            text={ctaUrlTextLeft}
                      />
          )}
        </LinkWrapper>
      </LeftContent>
      <RightContent large={isLargeRightCol} evencol={isEvenCol}>
        {rightContent &&
          rightContent.map(content => (
            <BlockRenderer
              nested={nestedGrid}
              key={content._key}
              block={content}
            />
          ))}
          <LinkWrapper>
          {ctaUrlExternalRight ? (
          <CTALink
            darkMode
            center
            url={ctaUrlExternalRight}
            text={ctaUrlTextRight}
                      />
          ) : ctaUrlInternalRight && (
          <CTALink
            center
            darkMode
            url={'/' + ctaUrlInternalRight[0].slug.current}
            text={ctaUrlTextRight}
                      />
          )}
        </LinkWrapper>
      </RightContent>
    </Wrapper>
  );
};

export default TwoColumnGrid;
