import React from 'react';
import styled from 'styled-components';

const StyledDiv = styled.div`
  height: ${props => props.height}px;
`;

const BlankBlock = ({ block: { height } }) => <StyledDiv height={height} />;

export default BlankBlock;
