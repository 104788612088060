import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { below, above } from '../styles';
import CTALink from '../elements/CTALink';
import ClientCard from './ClientCard';
import { Container, FixedImg } from '../elements';
import checkPropsEquality from '../utils/checkPropsEquality';
import toTitleCase from '../utils/toTitleCase';

const StyledContainer = styled.div`
  margin-left: 0;
  margin-right: 0;

  @media ${above.lg} {
    margin-right: 100px;
    margin-bottom: -120px;
  }

  @media ${above.xl} {
    margin-right: 140px;
  }

  @media ${above.xxl} {
    margin-right: 240px;
  }
`;

const IconTextContainer = styled.div`
  background-color: ${props => props.bgColor};
  color: ${props => props.theme.color.white};
  margin-right: 28px;

  @media ${above.sm} {
    margin-right: 50px;
  }

  @media ${above.md} {
    margin-right: 130px;
  }
  @media ${above.xl} {
    margin-right: 210px;
  }
`;

const IconText = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  min-height: 590px;
  padding-top: 50px;
  padding-bottom: 120px;
  position: relative;

  @media ${above.sm} {
    padding-top: 100px;
    padding-bottom: 100px;
  }
  @media ${above.lg} {
    margin-bottom: -250px;
  }
  @media ${above.xl} {
    min-height: 820px;
    justify-content: center;
    margin-bottom: -450px;
  }
`;

const Icons = styled.ul`
  margin-bottom: 10px;
  max-width: 630px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  @media (max-width: 400px) {
    margin-bottom: 40px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    &:after {
      content: '';
      flex: auto;
    }
  }

  flex-direction: row;

  @media ${above.lg} {
    margin-bottom: 40px;
  }
`;

const Icon = styled.li`
  width: calc(100% / 3);
  margin-bottom: 0;
  margin-right: 30px;
  img {
    width: 100% !important;
  }
  @media ${above.sm} {
    //width: 50%;
    margin-bottom: 30px;

  }
  @media (max-width: 750px) {
    margin-bottom: 25px;
    margin-right: 10px;
    width: calc(100% / 3.5);
  }
`;

const Heading = styled.h4`
  line-height: 1.8;
  max-width: 600px;
  font-size: 2.4rem;
  margin-top: 0 !important;

  @media ${above.md} {
    font-size: 4.6rem;
    line-height: 1.5;
  }
`;

const CardContainer = styled.div`
  margin-top: -80px;
  display: flex;
  justify-content: flex-end;
  padding-left: 28px;

  @media ${above.sm} {
    margin-top: -90px;
    max-width: 600px;
    margin-left: auto;
  }
  @media ${above.xl} {
    margin-top: -60px;
    position: sticky;
    bottom: 60px;
  }

  @media ${above.xxl} {
    max-width: 700px;
  }
`;

const IconTextCard = ({ block }) => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 750 ? true : false);
    };
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const {
    logoImageSettings,
    backgroundColor,
    partnerLogos,
    heading,
    ctaUrl,
    ctaText,
    card,
  } = block;

  const imgSrc =
    logoImageSettings === ('white' || 'dark')
      ? 'companyLogo' + toTitleCase(logoImageSettings)
      : 'companyLogo';
  const iconWidth = isMobile ? 80 : 175;
  return (
    <StyledContainer>
      <IconTextContainer bgColor={backgroundColor.hex}>
        <Container>
          <IconText>
            <Icons>
              {partnerLogos.map(logo => (
                <Icon key={logo._id}>
                  <FixedImg
                    asset={logo[imgSrc].asset}
                    alt={logo[imgSrc].alt}
                    width={iconWidth}
                  />
                </Icon>
              ))}
            </Icons>
            <Heading>{heading}</Heading>
            <CTALink url={ctaUrl[0].slug.current} text={ctaText} />
          </IconText>
        </Container>
      </IconTextContainer>
      <CardContainer>
        <ClientCard card={card} />
      </CardContainer>
    </StyledContainer>
  );
};

export default React.memo(IconTextCard, checkPropsEquality);
