import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { below, above, BREAKPOINTS } from '../styles/abstracts/breakpoints';

import { Container, Paragraph, ProgressCircle } from '../elements';
import checkPropsEquality from '../utils/checkPropsEquality';

const StyledContainer = styled(Container)`
  height: 100%;
  flex-wrap: wrap;
  z-index: -200;
`;

const Wrapper = styled.section`
  background: ${props => props.theme.color.grey.white};
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding-bottom: 30%;

  @media ${above.lg} {
    padding-bottom: 20%;
  }
  padding-top: 110px;
`;

const Heading = styled.h3`
  font-size: 5.2rem;
  line-height: 1.26;
  margin-bottom: 40px;

  @media ${below.xl} {
    margin-bottom: 0px !important;
    background: #fff;
    padding-bottom: 20px !important;
    width: 100%;
    padding-top: 20%;
  }

  @media ${above.xl} {
    font-size: 7.5rem;
  }
`;

const TextContainer = styled.div`
  position: sticky;
  top: 30%;
  width: 50%;
  height: 100%;
  @media ${below.xxxs} {
    top: 0em;
  }

  @media ${below.md} {
    padding-right: 0px;
  }

  @media ${below.xl} {
    width: 100%;
    top: 0%;
  }

  p {
    margin-bottom: 0px !important;
    @media ${above.xl} {
      width: 75%;
      float: left;
      max-width: 700px;
    }

    @media ${below.xl} {
      width: 100%;
      padding-bottom: 50px;
      background: #fff;
    }
  }
`;

const StyledProgressCircle = styled(ProgressCircle)`
  float: right;
`;

const TextContainerContent = styled.div`
  @media ${above.xxxl} {
    width: 50%;
  }

  @media ${above.xl} {
    width: 100%;
  }

  @media ${below.xl} {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
`;

const ItemContainer = styled.div`
  margin-top: 10%;
  margin-bottom: -1%;
  width: 50%;
  z-index: -10;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @media screen and (max-width: 1466px) {
    margin-top: 20%;
  }
  @media ${below.xl} {
    width: 100%;
    padding-left: 6.875em;
    margin-top: -8%;
    margin-bottom: 1%;
  }

  @media ${below.lg} {
    margin-top: -10%;
    margin-bottom: 3%;
  }
  @media ${below.md} {
    margin-top: -14%;
    margin-bottom: 2%;
  }
  @media ${below.sm} {
    margin-top: -22%;
    margin-bottom: 2%;
  }
  @media {
    max-width: 1;
  }
`;

const Item = styled.div`
  opacity: 0;
  transition: opacity 0.5s ease-out, transform 0.5s ease-out;
  margin-bottom: 8em;
  &:last-child {
    margin-bottom: 0em;
  }
`;

const Title = styled.div`
  margin-bottom: 0px;
  font-weight: bold;
  font-size: 1.5em;
  line-height: 34px;
  @media ${above.lg} {
    font-size: 2.25em;
    line-height: 50px;
  }
`;

const TextBlock = styled.div`
  width: 90%;
  padding-left: 28px;
  z-index: -10;
  @media ${above.sm} {
    padding-left: 50px;
  }

  @media ${above.lg} {
    padding-left: 80px;
  }
  @media ${below.sm} {
    font-size: 0.75em;
  }
`;

const ProgressCircles = ({ block: { heading, items = [], paragraph } }) => {
  const [progress, setProgress] = useState(0);
  const containerRef = useRef(null);

  useEffect(() => {
    const step = () => {
      const { current: container } = containerRef;
      const circleHeight = 110;

      if (container) {
        const windowHeight = window.innerHeight;
        const containerHeight = container.offsetHeight;
        const { top: offsetTop } = container.getBoundingClientRect();
        const top = windowHeight - circleHeight;
        const bottom = -containerHeight + (windowHeight / 2 + circleHeight);
        const percent = (offsetTop - top) / (bottom - top);
        const result = percent < 0 ? 0 : percent > 1 ? 1 : percent;

        setProgress(result * 100);
      }

      window.requestAnimationFrame(step);
    };

    window.requestAnimationFrame(step);
  }, []);

  const itemsRef = useRef([]);
  // you can access the elements with itemsRef.current[n]
  itemsRef.current = itemsRef.current.slice(0, items.length);

  useEffect(() => {
    const viewPortWidth = window.innerWidth;

    function getRootMargin() {
      if (viewPortWidth <= BREAKPOINTS.lg - 1) {
        return '-40% 0px -30% 0px';
      } else {
        return '-35% 0px -25% 0px';
      }
    }

    const options = {
      root: null,
      rootMargin: getRootMargin(),
      threshold: 1,
    };

    const observer = new IntersectionObserver(callback, options);

    for (let i = 0; i < items.length; i++) {
      observer.observe(itemsRef.current[i]);
    }

    function callback(entries) {
      entries.forEach(entry => {
        entry.target.style.opacity = entry.intersectionRatio;
      });
    }
  });

  const renderItem = (item, idx) => {
    const { _key, title } = item;

    return (
      <Item key={_key} ref={el => (itemsRef.current[idx] = el)}>
        <TextBlock>
          <Title>{title}</Title>
        </TextBlock>
      </Item>
    );
  };

  return (
    <StyledContainer>
      <Wrapper>
        <TextContainer>
          <TextContainerContent>
            <Heading>{heading}</Heading>
            <Paragraph text={paragraph} />
            <StyledProgressCircle
              size={110}
              strokeWidth={10}
              stroke="#30D998"
              value={progress}
              max={100}
              float="right"
            />
          </TextContainerContent>
        </TextContainer>
        <ItemContainer ref={containerRef}>
          {items.length > 0 && items.map((item, idx) => renderItem(item, idx))}
        </ItemContainer>
      </Wrapper>
    </StyledContainer>
  );
};

export default React.memo(ProgressCircles, checkPropsEquality);
