import React from 'react';
import styled from 'styled-components';
import Swiper from 'react-id-swiper';
import 'swiper/dist/css/swiper.min.css';

import { FluidImg } from '../elements';
import { above, below } from '../styles';
import checkPropsEquality from '../utils/checkPropsEquality';

const BackgroundWrapper = styled.div`
  background-color: ${props => props.theme.color.grey.superLight};
`;

const ColumnsWrapper = styled.div`
  padding-top: 125px;
  padding-bottom: 125px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 60px;
  margin-bottom: 60px;

  @media ${above.sm} {
    margin-left: auto;
    margin-right: auto;
  }

  @media ${above.md} {
    padding-top: 150px;
    padding-bottom: 150px;
    margin-left: auto;
    margin-right: auto;
    max-width: 100%;
    margin-top: 140px;
    margin-bottom: 140px;
  }

  @media ${above.xl} {
    margin-left: auto;
    margin-right: auto;
  }
`;

const ImgWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${props => props.slideWidth}px;
  max-width: 100%;
  height: 520px;

  img {
    width: auto !important;
    height: 520px;
    opacity: 1 !important;
  }
`;

const params = {
  slidesPerView: 'auto',
  centeredSlides: true,
  spaceBetween: 60,
  loop: true,
  loopAdditionalSlides: 3,
  speed: 4500,
  autoplay: {
    delay: 0,
  },
};

const ImageGallery = ({ block }) => {
  if (!block) {
    return null;
  }
  let isIE = false;
  if (typeof document !== `undefined`) {
    isIE = /*@cc_on!@*/ false || !!document.documentMode;
  }

  return (
    <BackgroundWrapper>
      <ColumnsWrapper>
        <Swiper {...params}>
          {block.images &&
            block.images.map(b => (
              <ImgWrap
                key={b._key}
                slideWidth={b.asset.metadata.dimensions.aspectRatio * 520}
              >
                <FluidImg
                  asset={b.asset}
                  alt={b.asset.alt}
                  loading={isIE ? 'eager' : 'lazy'}
                  imgStyle={{
                    opacity: 1,
                  }}
                />
              </ImgWrap>
            ))}
        </Swiper>
      </ColumnsWrapper>
    </BackgroundWrapper>
  );
};

export default React.memo(ImageGallery, checkPropsEquality);
