import React from 'react';
import BlankBlock from './BlankBlock';
import Card from './ClientCard';
import Tiles from './Tiles';
import IconTextCard from './IconTextCard';
import RichText from './RichText';
import Logos from './Logos';
import LogoText from './LogoText';
import MediaText from './MediaText';
import CTABlock from './CTABlock';
import CarouselCards from './CarouselCards';
import Timeline from './Timeline';
import ProgressCircles from './ProgressCircles';
import ContactForm from './ContactForm';
import ClientBanner from './ClientBanner';
import CurrentOpportunities from './CurrentOpportunities';
import PartnerCarousel from './PartnerCarousel';
import ImageGallery from './ImageGallery';
import FilteredTiles from './FilteredTiles';
import CaseStudyHero from './CaseStudyHero';
import ImageOnly from './ImageOnly';
import ImageText from './ImageText';
import TechStack from './TechStack';
import OneColumnGrid from './OneColumnGrid';
import TwoColumnGrid from './TwoColumnGrid';
import LogoGrid from './LogoGrid';
import Popup from './Popup';
import Table from './Table';

// individual blocks
const renderBlock = block => {
  switch (block._type) {
    case 'blank':
      return <BlankBlock key={block._key} block={block} />;
    case 'card':
      return <Card key={block._key} block={block} />;
    case 'tiles':
      return <Tiles key={block._key} block={block} />;
    case 'richText':
      return <RichText key={block._key} block={block} />;
    case 'partnerLogos':
      return <Logos key={block._key} block={block} />;
    case 'logoText':
      return <LogoText key={block._key} block={block} />;
    case 'mediaText':
      return <MediaText key={block._key} block={block} />;
    case 'iconTextCard':
      return <IconTextCard key={block._key} block={block} />;
    case 'ctaBlock':
      return <CTABlock key={block._key} block={block} />;
    case 'carouselCards':
      return <CarouselCards key={block._key} block={block} />;
    case 'timeline':
      return <Timeline key={block._key} block={block} />;
    case 'progressCircles':
      return <ProgressCircles key={block._key} block={block} />;
    case 'contactForm':
      return <ContactForm key={block._key} block={block} />;
    case 'currentOpportunities':
      return <CurrentOpportunities key={block._key} block={block} />;
    case 'clientBanner':
      return <ClientBanner key={block._key} block={block} />;
    case 'partnerCarousel':
      return <PartnerCarousel key={block._key} block={block} />;
    case 'imageGallery':
      return <ImageGallery key={block._key} block={block} />;
    case 'filteredTiles':
      return <FilteredTiles key={block._key} block={block} />;
    case 'techStack':
      return <TechStack key={block._key} block={block} />;
    case 'imageOnly':
      return <ImageOnly key={block._key} block={block} />;
    case 'imageText':
      return <ImageText key={block._key} block={block} />;
    case 'caseStudyHero':
      return <CaseStudyHero key={block._key} block={block} />;
    case 'oneColumnGrid':
      return <OneColumnGrid key={block._key} block={block} />;
    case 'twoColumnGrid':
      return <TwoColumnGrid key={block._key} block={block} />;
    case 'logoGrid':
      return <LogoGrid key={block._key} block={block} />;
    case 'popup':
      return <Popup key={block._key} block={block} />;
    case 'tableBlock':
      return <Table key={block._key} block={block} />;
    default:
      return null;
  }
};

const ContentBlocks = ({ pageBlocks }) =>
  (pageBlocks && pageBlocks.map(block => renderBlock(block))) || null;

export default ContentBlocks;
