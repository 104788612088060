import React from 'react';
import styled from 'styled-components';
import { below } from '../styles';

const Video = styled.video`
  width: 100%;
  height: auto;
  @supports (object-fit: cover) {
    object-fit: cover;
  }
`;

const EmbeddedVideo = ({ block }) => {
  const { url, centered } = block;

  return (
    <Video autoPlay loop muted playsInline>
      <source src={url} />
      Sorry, your browser doesn&apos;t support embedded videos.
    </Video>
  );
};

export default EmbeddedVideo;
