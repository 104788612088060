import React, { useEffect, useState } from 'react';
import { graphql, useStaticQuery, Link } from 'gatsby';
import styled from 'styled-components';
import { useWindowSize } from 'react-use';
import { imageUrlFor, buildImageObj } from '../utils/image-url';

import Navigation from './Navigation';
import MobileHeader from './MobileHeader';
import { BREAKPOINTS } from '../styles';
import { Container } from '../elements';

// Offset the hover effect on all anchor tags
const StyledLink = styled(Link)`
  &:hover {
    &:after {
      width: 0;
    }
  }
`;
const StyledHeader = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 3rem;
  padding-bottom: 3rem;
`;

const LogoWrapper = styled.div`
  max-width: 95px;

  img {
    width: 100%;
  }
`;

const Header = () => {
  const { width } = useWindowSize();
  const [isMobile, setIsMobile] = useState(() => width < BREAKPOINTS.md);

  const data = useStaticQuery(graphql`
    query HeaderQuery {
      companyInfo: sanityCompanyInfo {
        id
        logo: _rawCompanyPrimaryLogo(resolveReferences: { maxDepth: 3 })
      }
    }
  `);

  const {
    companyInfo: { logo },
  } = data;

  useEffect(() => {
    if (width < BREAKPOINTS.md) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  }, [width]);

  return (
    <Container>
      {isMobile ? (
        <MobileHeader />
      ) : (
        <StyledHeader>
          <StyledLink to="/">
            <LogoWrapper>
              <img
                src={imageUrlFor(buildImageObj(logo))
                  .width(100)
                  .auto('format')
                  .url()}
                alt={logo.alt}
              />
            </LogoWrapper>
          </StyledLink>
          <Navigation main />
        </StyledHeader>
      )}
    </Container>
  );
};
Header.propTypes = {};

Header.defaultProps = {
  siteTitle: ``,
};

export default Header;
