import React from 'react';
import styled from 'styled-components';

import { above } from '../styles';
import Logo from './Logo';

const Grid = styled.ul`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  margin: 55px 0;

  @media ${above.sm} {
    padding-top: 60px;
    justify-content: center;
  }
  @media ${above.md} {
    padding-top: 0;
  }
`;

const LogoWrap = styled.div`
  display: flex;
  justify-content: center;
  width: 45%;
  padding-top: 30px;
  padding-bottom: 30px;

  @media ${above.sm} {
    padding-top: 0;
    width: 33%;
    padding-bottom: 60px;
  }
  @media ${above.lg} {
    /** Last row - last 3 elements */
    &:nth-last-child(-n + 3) {
      padding-bottom: 0;
    }
  }
`;

const SLogo = styled(Logo)`
  width: 100px;
  @media ${above.md} {
    width: 130px;
  }
`;

const LogoGrid = ({ block }) => {
  return (
    <Grid>
      {block.logos.map(logo => (
        <LogoWrap key={logo._id}>
          <SLogo logo={logo} />
        </LogoWrap>
      ))}
    </Grid>
  );
};

export default LogoGrid;
