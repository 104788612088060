import React from 'react';
import styled, { css } from 'styled-components';
import TrackVisibility from 'react-on-screen';
import { above, below } from '../styles';
import PlayIcon from '../../static/icons/play';
import { Container, CTALink, Paragraph } from '../elements';
import { imageUrlFor, buildImageObj } from '../utils/image-url';
import checkPropsEquality from '../utils/checkPropsEquality';

// offset styles for Text Block

const StyledContainer = styled.div`
  margin-left: 0;
  margin-right: 0;

  @media ${above.lg} {
    margin-left: ${props => !props.alignMediaRight && '100px'};
    margin-right: ${props => props.alignMediaRight && '100px'};
  }

  @media ${above.xl} {
    margin-left: ${props => !props.alignMediaRight && '140px'};
    margin-right: ${props => props.alignMediaRight && '140px'};
  }

  @media ${above.xxl} {
    margin-left: ${props => !props.alignMediaRight && '240px'};
    margin-right: ${props => props.alignMediaRight && '240px'};
  }
`;
// Extra div needed for Safari
const ImgWrapper = styled.div`
  img {
    width: 100%;
    max-width: 100%;
    display: block;
  }
  @media ${above.lg} {
    position: sticky;
    top: 60px;
    margin-bottom: -60px;
  }
  @media ${above.lg} {
    position: sticky;
    bottom: 60px;
  }
`;

const CTALinkContainer = styled.div`
  padding-top: 30px;
`;

const ImgSection = styled.div`
  position: relative;
  z-index: 20;
  width: 95%;
  display: ${props => props.playingVideo && 'flex'};

  @media ${above.sm} {
    margin-left: ${props => props.alignRight && 'auto'};
    margin-right: ${props => !props.alignRight && 'auto'};
  }

  @media ${above.md} {
    margin-top: ${props => props.alignBottom && '-60px'};
    width: 45%;
    max-width: 600px;
  }

  @media ${above.lg} {
    //margin-top: -60px;
    max-width: 720px;
  }

  @media ${above.xxl} {
    width: 40%;
  }

  @supports (object-fit: cover) {
    img {
      width: 100% !important;
      height: 100% !important;
    }
  }

  &::before {
    content: '';
    width: 1px;
    margin-left: -1px;
    float: left;
    height: 0;
    padding-top: 100%;
  }

  /* tinted overlay for better text legibility */
  &::after {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 30;
    width: 100%;
    //background-image: linear-gradient(rgba(0, 0, 0, 0.15), rgba(0, 0, 0, 0.15));
    background-size: cover;
    content: '';
    display: table;
    clear: both;
  }
`;

const Video = styled.video`
  width: 100.1%;
  height: 100%;
  @supports (object-fit: cover) {
    object-fit: cover;
  }
`;

const ImageTextContent = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 50;
  margin-left: 50px;
  margin-right: 50px;
  margin-bottom: 15%;

  @media ${above.md} {
    margin-bottom: 50px;
  }

  @media ${above.lg} {
    margin-left: 75px;
    margin-right: 75px;
    margin-bottom: 75px;
  }
`;

const StyledPlayIcon = styled(PlayIcon)`
  width: 80px;
  height: 80px;

  @media ${above.lg} {
    width: 100px;
    height: 100px;
  }
`;

const ImageText = styled.h4`
  color: ${props => props.theme.color.white};
  font-size: 2.4rem;
  line-height: 1.416;
  margin-bottom: 1.6rem;
  width: 60%;

  @media ${above.sm} {
    width: 320px;
    font-size: 3.6rem;
  }

  @media ${above.lg} {
    width: 340px;
    font-size: 4.6rem;
    line-height: 1.39;
  }
`;

const TextSection = styled.div`
  background-color: ${props => props.bgcolor};
  color: ${props => props.bgcolor && props.theme.color.white};
  padding-top: 60%;
  padding-bottom: 100px;
  margin-left: 20px;
  margin-top: -70%;

  @media ${above.sm} {
    margin-left: ${props => (props.alignMediaRight ? '0px' : '20px')};
    margin-right: ${props => (props.alignMediaRight ? '20px' : '0px')};
    padding-top: 60%;
  }

  @media ${above.md} {
    margin-top: ${props => (props.alignBottom ? '0' : '-30%')};
    padding-top: 100px;
    padding-bottom: 100px;

    margin-left: ${props => (props.alignMediaRight ? '0px' : '10%')};
    margin-right: ${props => (props.alignMediaRight ? '10%' : '0px')};
  }

  @media ${above.lg} {
    padding-top: 130px;
    padding-bottom: 130px;
    margin-bottom: ${props => props.alignBottom && '-28%'};
  }

  @media ${above.xxl} {
    padding-top: 160px;
    padding-bottom: 160px;
  }
`;

const TextContent = styled.div`
  position: relative;
  z-index: 20;

  @media ${above.md} {
    padding-left: ${props => !props.alignMediaRight && '24%'};
    padding-right: ${props => props.alignMediaRight && '24%'};
  }
  @media ${above.lg} {
    padding-left: ${props => !props.alignMediaRight && '22%'};
    padding-right: ${props => props.alignMediaRight && '15%'};
  }
  @media ${above.xl} {
    padding-top: 60px;
    padding-left: ${props => !props.alignMediaRight && '19%'};
    padding-right: ${props => (props.alignMediaRight ? '19%' : '9%')};
  }

  @media ${above.xxl} {
    padding-left: ${props => !props.alignMediaRight && '16%'};
    padding-right: ${props => (props.alignMediaRight ? '13%' : '6%')};
  }
`;

const Content = styled.div`
  position: relative;

  ${props =>
    props.alignBottom &&
    css`
      @media ${above.md} {
        display: flex;
        flex-direction: column;
      }
    `}

  ${ImgSection} {
    order: ${props => props.alignBottom && 2};
    ${props =>
      !props.hasimage &&
      css`
        //background-color: ${props => props.theme.color.blue.navy};
        display: flex;
        align-items: flex-end;
      `}
  }

  ${ImgWrapper} {
    order: ${props => props.alignBottom && 2};
  }

  ${TextSection} {
    order: ${props => props.alignBottom && 1};
    ${props =>
      !props.hasimage &&
      css`
        @media ${below.md} {
          padding-top: 60%;
        }
      `}
  }
`;

const Heading = styled.h2`
  font-size: 5.2rem;
  line-height: 1.27;

  @media ${above.md} {
    max-width: ${props => (props.alignMediaRight ? '650px' : '850px')};
    margin-bottom: 40px;
    margin-left: ${props => props.alignMediaRight && 'auto'};
    margin-right: ${props => !props.alignMediaRight && 'auto'};
    text-align: ${props => (props.alignMediaRight ? 'right' : 'left')};
  }

  @media ${above.lg} {
    font-size: 7.5rem;
  }
`;

const VisibilityContent = styled.div``;

const VisibilityContainer = styled.div`
  line-height: 0;
  transition-property: clip-path;
  transition-duration: 0.3s;
  transition-timing-function: ease;
  transition-delay: ${props => (props.isVisible ? '0s' : '0.5s')};
  transform-origin: 0 0;
  overflow: hidden;
  background-color: ${props => props.theme.color.blue.darkNavy};
  clip-path: polygon(
    0 0,
    ${props => (props.isVisible ? '120%' : '0%')} 0,
    ${props => (props.isVisible ? '100%' : '0%')} 100%,
    0% 100%
  );
  ${VisibilityContent} {
    transition-property: opacity;
    transition-duration: 0.5s;
    transition-timing-function: ease;
    transition-delay: ${props => (props.isVisible ? '0.5s' : '0')};
    opacity: ${props => (props.isVisible ? '1' : '0')};
  }
`;

const ParagraphWrapper = styled.div`
  max-width: 600px;

  margin-left: ${props => !props.alignMediaRight && 'auto'};
  margin-right: ${props => props.alignMediaRight && 'auto'};

  p {
    font-size: 1.8rem;
    line-height: 1.89;
    margin-bottom: 2em;

    &:last-of-type {
      margin-bottom: 0;
    }

    @media ${above.md} {
      font-size: 1.8rem;
      line-height: 1.89;
    }
  }
`;

const MediaText = ({ block }) => {
  const {
    imageText,
    Image: image,
    videoUrl,
    heading,
    paragraph,
    ctaText,
    ctaUrl,
    ctaRelativeUrl,
    backgroundColor,
    mediaHorizontalPosition,
    mediaVerticalPosition,
  } = block;

  const alignMediaRight = mediaHorizontalPosition === 'right';
  const alignMediaBottom = mediaVerticalPosition === 'bottom';

  // main contents go here
  return (
    <StyledContainer alignMediaRight={alignMediaRight}>
      <Content alignBottom={alignMediaBottom} hasimage={image}>
        <ImgWrapper alignBottom={alignMediaBottom}>
          <ImgSection
            alignRight={alignMediaRight}
            alignBottom={alignMediaBottom}
            playingVideo={videoUrl}
          >
            <TrackVisibility
              //once='true'
              offset={50}
            >
              <VisibilityContainer>
                <VisibilityContent>
                  {videoUrl ? (
                    <div>
                      <Video autoPlay loop muted playsInline>
                        <source src={videoUrl} />
                        Sorry, your browser doesn&apos;t support embedded
                        videos.
                      </Video>
                    </div>
                  ) : (
                    <>
                      {image && (
                        <img
                          src={imageUrlFor(buildImageObj(image))
                            .width(750)
                            .height(750)
                            .auto('format')
                            .url()}
                          alt={image.alt}
                        />
                      )}
                      {imageText && (
                        <ImageTextContent>
                          <ImageText>{imageText}</ImageText>
                        </ImageTextContent>
                      )}
                    </>
                  )}
                </VisibilityContent>
              </VisibilityContainer>
            </TrackVisibility>
          </ImgSection>
        </ImgWrapper>
        <TextSection
          alignMediaRight={alignMediaRight}
          alignBottom={alignMediaBottom}
          bgcolor={backgroundColor && backgroundColor.hex}
        >
          <Container>
            <TextContent alignMediaRight={alignMediaRight}>
              <Heading alignMediaRight={alignMediaRight}>{heading}</Heading>
              <ParagraphWrapper alignMediaRight={alignMediaRight}>
                <Paragraph text={paragraph} />
              </ParagraphWrapper>
              {ctaText && ctaUrl && (
                <CTALinkContainer>
                  <CTALink
                    text={ctaText}
                    url={
                      ctaRelativeUrl
                        ? ctaRelativeUrl
                        : ctaUrl && ctaUrl[0].slug.current
                    }
                  />
                </CTALinkContainer>
              )}
            </TextContent>
          </Container>
        </TextSection>
      </Content>
    </StyledContainer>
  );
};

export default React.memo(MediaText, checkPropsEquality);
