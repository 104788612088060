import { graphql, useStaticQuery } from 'gatsby';
import React, { useState } from 'react';
import styled, { keyframes } from 'styled-components';
import { FaChevronRight, FaTimes } from 'react-icons/fa';
import { useSpring, animated } from 'react-spring';
import { useMeasure } from 'react-use';
import { imageUrlFor, buildImageObj } from '../utils/image-url';
import { Container, FixedImg } from '../elements';
import { below, above } from '../styles';

const AnimatedRoot = styled(animated.div)`
  height: auto;
  background-color: ${props => props.theme.color.grey.superLight};
  width: 100%;
  overflow: none;
`;

const OuterSpringDiv = styled.div`
  position: relative;
`;

const InnerSpringDiv = styled.div`
  background-color: ${props => props.theme.color.grey.superLight};
  padding-top: 126px;
  padding-bottom: 80px;
  @media ${below.lg} {
    padding-top: 63px;
    padding-bottom: 99px;
  }
`;

const MenuContainer = styled.div`
  display: flex;
  flex-direction: column;

  padding-top: 0px;
  min-height: 0;
  width: 100%;

  @media ${above.lg} {
    width: 48%;
  }
`;

const ListingContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  @media ${above.lg} {
    width: 45%;
    margin-top: 20px;
  }
`;
const HeaderText = styled.h2`
  font-size: 5.2rem;
  line-height: 1.26;
  font-weight: 600;
  color: ${props => props.theme.color.blue.lightNavy};
  margin-bottom: 35px;
  @media ${above.md} {
    font-size: 7.2rem;
  }

  @media ${above.xl} {
    font-size: 7.5rem;
  }
`;
const Filters = styled.div`
  display: flex;
  @media ${below.lg} {
    overflow-x: scroll;
    margin-bottom: 35px;
  }
`;
const FilterText = styled.div`
  font-size: 2rem;
  line-height: 1;
  margin-right: 25px;
  white-space: nowrap;
  cursor: pointer;
  color: ${props => props.theme.color.blue.lightNavy};
  display: inline;

  /*Used different approach to the animated underline here to support
  multiple lines. Hardcoded color value for now as linear-gradient does
  not appear to work with props */
  background-image: linear-gradient(#30d998, #30d998);
  background-position: 0% 90%;
  background-repeat: no-repeat;
  background-size: 0% 5px;
  transition: background-size 0.3s;

  @media ${above.md} {
    margin-right: 30px;
    font-size: 2.2rem;
  }

  &:hover {
    background-size: 100% 5px;
  }
`;
const SelectedFilter = styled(FilterText)`
  font-weight: bold;
  color: ${props => props.theme.color.blue.lightNavy};

  &:after {
    content: '';
    height: 5px;
    background: ${props => props.theme.color.green};
    display: block;
    margin-top: -4px;
  }
  &:hover {
    background-size: 0%;
  }
`;
const CategoryContainer = styled.div`
  display: flex;
  flex-direction: column;
`;
const CategoryWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-left: 5px;
`;
const CategoryText = styled.div`
  color: ${props => props.theme.color.green};
  font-size: 2.2rem;
  line-height: 1.18;
  font-weight: 600;
  margin-left: 30px;
  @media ${below.md} {
    margin-left: 25px;
  }
`;
const OpportunityTextWrapper = styled.div`
  margin-left: 65px;
  position: relative;
  @media ${below.md} {
    margin-left: 60px;
    display: flex;
    margin-bottom: 20px;
  }
  margin-top: 30px;
  margin-bottom: 30px;
`;
const RightArrow = styled(FaChevronRight)`
  margin-top: 9px;
  font-size: 2rem;
  position: absolute;
  right: 0;

  @media ${above.md} {
    //display: none;
  }
`;
const OpportunityButton = styled.button`
  border: none;
  background-color: ${props => props.theme.color.grey.superLight};
  padding: 0;
  text-align: left;
`;
const OpportunityText = styled.div`
  line-height: 33px;
  font-size: 2.2rem;
  font-weight: bold;
  display: inline;
  cursor: pointer;
  color: ${props => props.theme.color.blue.lightNavy};

  background-image: linear-gradient(#30d998, #30d998);
  background-position: 0% 90%;
  background-repeat: no-repeat;
  background-size: 0% 5px;
  transition: background-size 0.3s;
  &:hover {
    background-size: 100% 5px;
  }

  @media ${below.md} {
    font-size: 2rem;
    line-height: 1.7;
  }
`;

const Line = styled.div`
  background-color: #e2e2e2;
  height: 1px;
`;

const fadeInOpacity = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

const MenuTransition = styled.div`
  opacity: 1;
  animation-name: ${fadeInOpacity};
  animation-iteration-count: 1;
  animation-timing-function: ease-in;
  animation-duration: 0.5s;
`;
const MenuTransition2 = styled(MenuTransition)``;

const FlexWrapper = styled.div`
  @media ${above.lg} {
    display: flex;
    justify-content: space-between;
  }
`;
const CategoryBlockWrapper = styled.div`
  margin-bottom: 20px;
  @media ${above.lg} {
    margin-bottom: 55px;
  }
`;

const CurrentOpportunities = ({ block }) => {
  const data = useStaticQuery(graphql`
    query JobCategoriesQuery {
      jobCategories: allSanityOpportunityCategory {
        edges {
          node {
            categoryIcon {
              asset {
                fixed(width: 36) {
                  ...GatsbySanityImageFixed_noBase64
                }
              }
            }
            categoryName
          }
        }
      }
    }
  `);
  const { jobCategories } = data;
  const [selectedFilter, setSelectedFilter] = useState('All openings');
  const [ref, { height }] = useMeasure();
  const springProps = useSpring({
    height,
  });

  const jobPopup = (url, title, w, h) => {
    const dualScreenLeft =
      window.screenLeft != undefined ? window.screenLeft : screen.left;
    const dualScreenTop =
      window.screenTop != undefined ? window.screenTop : screen.top;
    const width = window.innerWidth
      ? window.innerWidth
      : document.documentElement.clientWidth
      ? document.documentElement.clientWidth
      : screen.width;
    const height = window.innerHeight
      ? window.innerHeight
      : document.documentElement.clientHeight
      ? document.documentElement.clientHeight
      : screen.height;
    const left = width / 2 - w / 2 + dualScreenLeft;
    const top = height / 2 - h / 2 + dualScreenTop;
    const newWindow = window.open(
      url + '&popup=true',
      title,
      'scrollbars=yes, width=' +
        w +
        ', height=' +
        h +
        ', top=' +
        top +
        ', left=' +
        left
    );
    if (window.focus) {
      newWindow.focus();
    }
  };
  const allOpeningsFilter =
    selectedFilter === 'All openings' ? (
      <SelectedFilter key="All openings">All Openings</SelectedFilter>
    ) : (
      <FilterText
        key="All openings"
        onClick={() => setSelectedFilter('All openings')}
      >
        All openings
      </FilterText>
    );

  const filters = jobCategories.edges.map(category => {
    if (category.node.categoryName === selectedFilter) {
      return (
        <SelectedFilter key={category.node.categoryName}>
          {category.node.categoryName}
        </SelectedFilter>
      );
    } else {
      return (
        <FilterText
          key={category.node.categoryName}
          onClick={() => setSelectedFilter(category.node.categoryName)}
        >
          {category.node.categoryName}
        </FilterText>
      );
    }
  });

  const listings = block.opportunities.map(opportunity => {
    if (opportunity.opportunityCategory.categoryName === selectedFilter) {
      return (
        <div key={opportunity.id}>
          <OpportunityTextWrapper
            onClick={() => jobPopup(opportunity.opportunityLink, '', 1000, 700)}
          >
            <OpportunityButton>
              <OpportunityText>{opportunity.opportunityName}</OpportunityText>
            </OpportunityButton>
            <RightArrow />
          </OpportunityTextWrapper>
          <Line />
        </div>
      );
    } else {
      return null;
    }
  });
  const displayAll = jobCategories.edges.map(category => {
    let categoryImage;
    jobCategories.edges.forEach(edge => {
      if (edge.node.categoryName === category.node.categoryName) {
        categoryImage = edge.node.categoryIcon;
      }
    });

    if (category.node.categoryName !== 'All openings') {
      const categoryListings = block.opportunities.map(opportunity => {
        if (
          opportunity.opportunityCategory.categoryName ===
          category.node.categoryName
        ) {
          return (
            <div key={opportunity.id}>
              <OpportunityTextWrapper
                onClick={() =>
                  jobPopup(opportunity.opportunityLink, '', 1000, 700)
                }
              >
                <OpportunityButton>
                  <OpportunityText>
                    {opportunity.opportunityName}
                  </OpportunityText>
                </OpportunityButton>
                <RightArrow />
              </OpportunityTextWrapper>
              <Line />
            </div>
          );
        } else {
          return null;
        }
      });

      const categoryName = category.node.categoryName;
      return (
        <CategoryBlockWrapper>
          <MenuTransition>
            <CategoryContainer key={categoryName}>
              <CategoryWrapper>
                <img src={categoryImage.asset.fixed.src} width="30" />
                <CategoryText>{categoryName}</CategoryText>
              </CategoryWrapper>
              {categoryListings}
            </CategoryContainer>
          </MenuTransition>
        </CategoryBlockWrapper>
      );
    } else {
      return null;
    }
  });
  let selectedCategoryImage;
  jobCategories.edges.forEach(category => {
    if (category.node.categoryName == selectedFilter) {
      selectedCategoryImage = category.node.categoryIcon;
    }
  });
  const display =
    selectedFilter === 'All openings' ? (
      displayAll
    ) : (
      <MenuTransition2>
        <CategoryContainer>
          <CategoryWrapper>
            <img src={selectedCategoryImage.asset.fixed.src} width="30" />
            <CategoryText>{selectedFilter}</CategoryText>
          </CategoryWrapper>
          {listings}
        </CategoryContainer>
      </MenuTransition2>
    );
  return (
    <AnimatedRoot style={springProps} id="opportunities">
      <OuterSpringDiv ref={ref}>
        <InnerSpringDiv>
          <Container>
            <FlexWrapper>
              <MenuContainer>
                <HeaderText>Current opportunities.</HeaderText>
                <Filters>
                  {allOpeningsFilter}
                  {filters}
                </Filters>
              </MenuContainer>
              <ListingContainer>{display}</ListingContainer>
            </FlexWrapper>
          </Container>
        </InnerSpringDiv>
      </OuterSpringDiv>
    </AnimatedRoot>
  );
};

export default CurrentOpportunities;
