import React, { useState, useEffect } from 'react';
import styled, { css } from 'styled-components';
import { useWindowSize } from 'react-use';
import { get } from 'lodash';

import { Paragraph, Container } from '../elements';
import { above, below, BREAKPOINTS } from '../styles';
import Tile from './Tile';

const LeftCol = styled.div`
  width: 100%;
  margin-top: 50px;
  margin-bottom: 20px;

  @media ${above.md} {
    width: calc(50% - 25px);
    margin-top: 220px;
    margin-bottom: 0;
  }
`;
const RightCol = styled.div`
  width: 100%;
  @media ${above.md} {
    width: calc(50% - 25px);
  }
`;

const TeaserWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  @media ${above.md} {
    margin-top: -25px;
    ${props =>
      props.leftSpace ? 'max-width: 1000px; margin-left: auto;' : ''};
  }
  @media ${above.lg} {
    margin-top: 0px;
  }
  ${LeftCol} {
    ${props =>
      props.smallLeftCol &&
      css`
        @media ${above.md} {
          width: calc(46% - 25px);
        }
      `}
  }
  ${RightCol} {
    ${props =>
      props.smallLeftCol &&
      css`
        @media ${above.md} {
          width: calc(54% - 25px);
        }
      `}
  }
`;

const TilesWrapper = styled.section`
  ${props => props.bgColor && `background-color: ${props.bgColor}`};
  padding-bottom: ${props => props.bottomSpace && '0px'};
  @media ${above.md} {
    padding-bottom: ${props => props.bottomSpace && '120px'};
  }
`;

const Title = styled.h2`
  padding-top: 85px;
  @media ${below.md} {
    padding-top: 70px;
    padding-top: 100px;
  }
  margin-bottom: 20px !important;
  max-width: 522px;
  font-size: 5.2rem;
  line-height: 1.269;
  @media ${above.xl} {
    font-size: 7.5rem;
    line-height: 1.256;
  }
`;

const ParagraphWrapper = styled.div`
  @media ${below.md} {
    padding-bottom: 60px;
  }
  @media ${above.md} {
    max-width: 545px;
  }
  @media ${above.lg} {
    max-width: 700px;
  }
`;

const StyledContainer = styled.div`
  @media ${above.sm} {
    margin-left: 50px;
    margin-right: 50px;
  }
  @media ${above.md} {
    margin-left: 50px;
    margin-right: 50px;
  }
  @media ${above.lg} {
    margin-left: 90px;
    margin-right: 90px;
  }
`;

const Tiles = ({
  block: {
    backgroundColor,
    title,
    paragraph,
    leftColBlocks,
    rightColBlocks,
    wideDisplay,
    leftSpace,
    bottomSpace,
    smallLeftCol,
  },
}) => {
  // We will be rendering this
  const [mobileTiles, setMobileTiles] = useState([]);

  const Component = wideDisplay ? StyledContainer : Container;
  const { width } = useWindowSize();
  const isMobile = width < BREAKPOINTS.md;

  useEffect(() => {
    const mobileTiles = [
      ...leftColBlocks.filter(
        item => item._type !== 'clientLogo' && item._type !== 'paragraphTile'
      ),
      ...rightColBlocks.filter(
        item => item._type !== 'clientLogo' && item._type !== 'paragraphTile'
      ),
      ...leftColBlocks.filter(
        item => item._type === 'clientLogo' || item._type === 'paragraphTile'
      ),
      ...rightColBlocks.filter(
        item => item._type === 'clientLogo' || item._type === 'paragraphTile'
      ),
    ];

    setMobileTiles(mobileTiles);
  }, []);

  const renderMobile = () =>
    mobileTiles.map(item => (
      // some blocks only have key but not id, and vice versa
      <Tile key={get(item, '_key', get(item, '_id'))} content={item} />
    ));

  const renderDesktop = () => (
    <>
      <LeftCol>
        {leftColBlocks &&
          leftColBlocks.map((item, idx) => (
            // some blocks only have key but not id, and vice versa
            <Tile
              key={get(item, '_key', get(item, '_id'))}
              content={item}
              lastLeftTile={
                idx === rightColBlocks.length - 1 &&
                item._type !== 'plainTile' &&
                item._type !== 'testimonial' &&
                item._type !== 'embeddedVideo' &&
                item._type !== 'youtube'
              }
            />
          ))}
      </LeftCol>
      <RightCol>
        {rightColBlocks &&
          rightColBlocks.map((item, idx) => (
            // some blocks only have key but not id, and vice versa
            <Tile
              key={get(item, '_key', get(item, '_id'))}
              content={item}
              lastRightTile={
                idx === rightColBlocks.length - 1 &&
                item._type !== 'plainTile' &&
                item._type !== 'testimonial' &&
                item._type !== 'embeddedVideo' &&
                item._type !== 'youtube'
              }
            />
          ))}
      </RightCol>
    </>
  );

  return (
    <TilesWrapper
      bgColor={backgroundColor && backgroundColor.hex}
      bottomSpace={bottomSpace}
    >
      <Component>
        {title && <Title>{title}</Title>}
        {paragraph && (
          <ParagraphWrapper>
            <Paragraph text={paragraph} />
          </ParagraphWrapper>
        )}
        {/* The Tiles inside can be client cards, blog posts, testimonials, images or company icons */}
        <TeaserWrapper leftSpace={leftSpace} smallLeftCol={smallLeftCol}>
          {isMobile ? renderMobile() : renderDesktop()}
        </TeaserWrapper>
      </Component>
    </TilesWrapper>
  );
};

export default Tiles;
