import React, { useState } from 'react';
import { graphql, useStaticQuery, Link } from 'gatsby';
import styled from 'styled-components';

import { IoMdClose } from 'react-icons/io';
import { Match } from '@reach/router';
import { above } from '../styles';

const StyledLink = styled(Link)`
  color: ${props =>
    props.main ? props.theme.color.blue.navy : props.theme.color.white};
  background-image: linear-gradient(
    ${props => props.theme.color.green},
    ${props => props.theme.color.green}
  );
  background-position: ${props => (props.current ? '100%' : '0%')} 87%;
  background-repeat: no-repeat;
  background-size: ${props => (props.current ? '100%' : '0%')} 5px;
  transition: 400ms;

  &:visited {
    color: ${props => (props.main ? props.theme.color.blue.navy : 'inherit')};
  }

  &:hover {
    &&& {
      opacity: 1;
      background-size: 100% 5px;
    }
  }
`;

const Nav = styled.ul`
  display: flex;
  justify-content: space-between;
  align-items: center;

  ${StyledLink} {
    opacity: ${props => props.hover && '0.4'};
  }
`;

const NavItem = styled.li`
  position: relative;
  margin: 0 10px;

  @media ${above.sm} {
    margin-left: 35px;
  }
`;

const SlideDown = styled.div`
  transform: ${props =>
    props.dropdownOpen ? 'translateY(0)' : 'translateY(-100%)'};
  transition: transform 0.3s ease-in-out;
  height: 0;
  display: none;
`;

const DropdownMenu = styled(SlideDown)`
  position: absolute;
  font-weight: bold;
  left: 0;
  top: 0;
  background-color: ${props => props.theme.color.grey.superLight};
  color: ${props => props.theme.color.blue.lightNavy};
  z-index: 35;
  width: 100%;
  height: 100%;
`;

const InnerContainer = styled.div`
  width: 35%;
  margin-left: 55%;
  max-width: 400px;
  padding-top: 60px;

  @media ${above.lg} {
    margin-left: 65%;
  }
`;

const DropdownHeader = styled.div`
  width: 100%;
  height: 100px;
`;

const DropdownLinks = styled.ul`
  height: auto;
  margin-top: 15px;
  text-align: right;
  width: 100%;
  font-size: 36px;
  line-height: 81px;
`;

const TextWrapper = styled.div`
  float: left;
  margin-top: 35px;
`;

const CloseMenu = styled(IoMdClose)`
  fill: ${props => props.theme.color.blue.navy};
  font-size: 48px;
  float: right;
  &:hover {
    opacity: 0.8;
  }
`;

const DropdownCategory = styled.div`
  font-size: 22px;
  text-align: right;
  line-height: 46px;
`;

const Navigation = ({ main, footer }) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [hover, setHover] = useState(false);

  const isSSR = typeof window === 'undefined';

  const data = useStaticQuery(graphql`
    query NavigationQuery {
      menu: sanityMenu {
        mainMenu {
          displayName
          pageRef {
            slug {
              current
            }
            title
          }
          expandedItems {
            slug {
              current
            }
            title
          }
        }
        footerMenu {
          slug {
            current
          }
          title
        }
      }
    }
  `);

  const {
    menu: { mainMenu, footerMenu },
  } = data;

  let dropdownMenuItems;

  mainMenu.forEach(menuItem => {
    if (menuItem.displayName == dropdownOpen) {
      dropdownMenuItems = menuItem.expandedItems.map(expandedItem => (
        <li key={expandedItem.id}>
          <StyledLink main={1} to={`/${expandedItem.slug.current}`}>
            {expandedItem.title}
          </StyledLink>
        </li>
      ));
    }
  });
  let dropdownCategory = '';
  if (dropdownOpen !== false) {
    dropdownCategory = dropdownOpen;
  }

  return (
    <Nav hover={hover}>
      <DropdownMenu dropdownOpen={dropdownOpen}>
        <InnerContainer>
          <DropdownHeader>
            <CloseMenu onClick={() => setDropdownOpen(false)} />
          </DropdownHeader>
          <TextWrapper>
            <DropdownCategory>{dropdownCategory}</DropdownCategory>
            <DropdownLinks>{dropdownMenuItems}</DropdownLinks>
          </TextWrapper>
        </InnerContainer>
      </DropdownMenu>

      {!!main &&
        mainMenu.map(menu => {
          const isNestedMenu = menu.expandedItems.length > 0;

          const slug = menu.pageRef.slug.current;

          return (
            <NavItem
              key={menu.displayName}
              onClick={() => {
                if (isNestedMenu) setDropdownOpen(menu.displayName);
              }}
            >
              {!isSSR && (
                <Match path={`/${slug}`}>
                  {props => (
                    <StyledLink
                      main="true"
                      onMouseEnter={() => setHover(true)}
                      onMouseLeave={() => setHover(false)}
                      to={`/${slug}`}
                      current={props.match}
                    >
                      {menu.displayName}
                    </StyledLink>
                  )}
                </Match>
              )}
            </NavItem>
          );
        })}

      {!!footer &&
        footerMenu.map(menu => (
          <NavItem key={menu.title}>
            <StyledLink to={`/${menu.slug.current}`}>{menu.title}</StyledLink>
          </NavItem>
        ))}
    </Nav>
  );
};

export default Navigation;
