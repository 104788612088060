import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import Swiper from 'react-id-swiper';
import { useWindowSize } from 'react-use';

import Card from './CarouselCard';
import { BREAKPOINTS, above } from '../styles';
import { Container } from '../elements';
import 'swiper/dist/css/swiper.min.css';

const CarouselBlock = styled.section`
  background-color: ${props => props.theme.color.grey.superLight};
  padding-top: 100px;
  padding-bottom: 100px;
  @media ${above.lg} {
    padding-top: 140px;
    padding-bottom: 140px;
  }
`;

const Heading = styled.h2`
  margin-bottom: 0.5em;
  color: ${props => props.theme.color.blue.navy};
  max-width: 930px;
  font-size: 5.2rem;
  @media ${above.lg} {
    font-size: 7.5rem;
  }
`;

const StackedCards = styled.div`
  padding: 20px;
`;

const params = {
  slidesPerView: 3.2,
  spaceBetween: 60,
  speed: 4000,
  loop: true,
  autoplay: {
    delay: 0,
    reverseDirection: true,
  },
  breakpoints: {
    900: { slidesPerView: 2.5, spaceBetween: 20 },
    1200: { slidesPerView: 2.5, spaceBetween: 30 },
    1600: { slidesPerView: 3.3, spaceBetween: 40 },
  },
};

const CarouselCards = ({ block: { heading, cards } }) => {
  const { width } = useWindowSize();
  const [stackCards, setStackCards] = useState(false);

  // keep track of card index that are showing details
  const [activeIndex, setActiveIndex] = useState(null);

  useEffect(() => {
    if (width < BREAKPOINTS.sm) {
      setStackCards(true);
    } else {
      setStackCards(false);
    }
  }, [width]);

  return (
    <CarouselBlock>
      <Container>
        <Heading>{heading}</Heading>
      </Container>
      {stackCards ? (
        <StackedCards>
          {cards &&
            cards.map((c, idx) => (
              <Card
                id={idx}
                showDetails={idx === activeIndex}
                setActiveIndex={setActiveIndex}
                key={c._key}
                card={c}
                stacked
              />
            ))}
        </StackedCards>
      ) : (
        <Swiper {...params}>
          {cards &&
            cards.map((c, idx) => (
              <Card
                id={idx}
                showDetails={idx === activeIndex}
                setActiveIndex={setActiveIndex}
                key={c._key}
                card={c}
              />
            ))}
        </Swiper>
      )}
    </CarouselBlock>
  );
};

export default CarouselCards;
