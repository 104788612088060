import React from 'react';
import styled from 'styled-components';
import { FixedImg } from '../elements';
import { above, below } from '../styles';
import TrackVisibility from 'react-on-screen';

const Card = styled.div`
  padding: 45px;
  min-height: 275px;
  background-color: ${props =>
    props.backgroundColor ? props.backgroundColor : props.theme.color.white};
  color: ${props => (props.whiteText ? props.theme.color.white : '')};

  @media ${below.md} {
    padding: 40px 20px 40px 20px;
  }
`;
const Content = styled.div`
  padding-top: 25%;
`;
const Title = styled.h3`
  font-weight: bold;
  line-height: 1.67;
  font-size: 2.4rem;
  margin-bottom: 30px;

  @media ${above.lg} {
    font-size: 3.6rem;
    line-height: 1.38;
    max-width: 523px;
  }
`;
const Info = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 14px;
  color: ${props =>
    props.whiteText
      ? props.theme.color.white
      : props.theme.color.blue.lightNavy};
`;

const Details = styled.div`
  display: flex;
  align-items: center;
`;

const JobTitle = styled.p`
  margin-bottom: 10px;
  line-height: 1;

  @media ${below.md} {
    font-size: 1.6rem;
  }
`;

const Name = styled.p`
  font-weight: bold;
  margin-bottom: 0;
  line-height: 1;

  @media ${below.md} {
    font-size: 1.6rem;
  }
`;
const ImgWrapper = styled.div`
  height: 70px;
`;

const VisibilityContent = styled.div`

`;

const VisibilityContainer = styled.div`
  line-height: 0;
  @media ${above.md} {
    transition-property: clip-path transform box-shadow;
    transition-duration: 0.5s;
    transition-timing-function: ease;
    transition-delay: ${props => props.isVisible ? '0s' : '0.5s'};
    //transform-origin: 0 0;
    overflow: hidden;
    background-color: ${props =>
    props.backgroundColor ? props.backgroundColor : props.theme.color.white};
    clip-path: polygon(-20% -40%, ${props => props.isVisible ? '140%' : '0%'} 0, ${props => props.isVisible ? '120%' : '0%'} 120%, -20% 120%);
    ${VisibilityContent} {
      transition-property: opacity;
      transition-duration: 0.5s;
      transition-timing-function: ease;
      transition-delay: ${props => props.isVisible ? '0.5s' : '0'};
      opacity: ${props => props.isVisible ? '1' : '0'};
    }
  }
`;
const TestimonialCard = ({ testimonial }) => {
  return (
    <TrackVisibility
        once='true'
        >
      <VisibilityContainer
        backgroundColor={
              testimonial.backgroundColor && testimonial.backgroundColor.hex
            }
        >
        <VisibilityContent>
          <Card
            backgroundColor={
              testimonial.backgroundColor && testimonial.backgroundColor.hex
            }
            whiteText={testimonial.whiteText && testimonial.whiteText}
            rightAlign={testimonial.rightAlign && testimonial.rightAlign}
          >
            <Content>
              <Title>{testimonial.headline}</Title>
              <Details>
                {testimonial.image && (
                  <ImgWrapper>
                    <FixedImg
                      asset={testimonial.image.asset}
                      width={70}
                      wrapperStyle={{ borderRadius: '50%' }}
                    />
                  </ImgWrapper>
                )}
                <Info whiteText={testimonial.whiteText && testimonial.whiteText}>
                  <JobTitle>{testimonial.jobTitle}</JobTitle>
                  <Name>{testimonial.name}</Name>
                </Info>
              </Details>
            </Content>
          </Card>
        </VisibilityContent>
      </VisibilityContainer>
    </TrackVisibility>
  );
};

export default TestimonialCard;
