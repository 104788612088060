import React from 'react';
import styled from 'styled-components';
import { above, below } from '../styles';
import { Container, Paragraph, FluidImg } from '../elements';
import checkPropsEquality from '../utils/checkPropsEquality';

const LogoTextWrapper = styled.section`
  padding-top: 70px;
  padding-bottom: 70px;

  @media ${above.md} {
    padding-top: 140px;
    padding-bottom: 140px;
    display: flex;
    justify-content: space-between;
  }
  @media ${above.lg} {
    padding-top: 200px;
    padding-bottom: 200px;
  }
  @media ${above.xl} {
    padding-top: 250px;
    padding-bottom: 250px;
  }
`;

const LogoBlock = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;

  &:after {
    content: '';
    width: 30%;
  }
  @media ${below.lg} {
    margin-bottom: 60px;
  }
  @media ${above.md} {
    width: 45%;
  }
`;

const Lists = styled.ul`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
`;

const List = styled.li`
  margin-bottom: 50px;
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  @media ${above.lg} {
    width: 28%;
  }
`;

const ImgWrapper = styled.div`
  margin-top: 20px;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

const TextBlock = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 20px;

  @media ${above.md} {
    width: 45%;
  }
`;
const Heading = styled.h2`
  font-size: 1.8rem;
  margin-bottom: 1.5em;
  line-height: 1.55;

  @media ${above.lg} {
    font-size: 2.2rem;
    margin-bottom: 1.3em;
  }
`;

const ParagraphWrapper = styled.div`
  margin-bottom: 0;
  @media ${above.lg} {
    font-size: 1.8rem;
  }
`;

const LogoText = data => {
  const {
    block: { heading, paragraph, clients },
  } = data;

  return (
    <Container>
      <LogoTextWrapper>
        <LogoBlock>
          <Lists>
            {clients.map(client => (
              <List key={client.id}>
                <ImgWrapper>
                  <FluidImg
                    asset={client.companyLogo.asset}
                    wrapperStyle={{
                      width: '100%',
                      maxWidth: '120px',
                      height: 'auto',
                    }}
                  />
                </ImgWrapper>
              </List>
            ))}
          </Lists>
        </LogoBlock>
        <TextBlock>
          <Heading>{heading}</Heading>
          <ParagraphWrapper>
            <Paragraph text={paragraph} />
          </ParagraphWrapper>
        </TextBlock>
      </LogoTextWrapper>
    </Container>
  );
};

export default React.memo(LogoText, checkPropsEquality);
