import React from 'react';
import styled from 'styled-components';
//import { Remarkable } from 'remarkable';
//import { linkify } from 'remarkable/linkify';
import { above, below } from '../styles';

//var md = new Remarkable({}).use(linkify);

const StyledDiv = styled.table`
  margin: 100px 20px 100px 20px;
  width: calc(100% - 40px);
  a {
    color: ${props => props.theme.color.green} !important;
  }
  p {
    margin: 0px;
  }
  @media ${above.md} {
    margin: auto;
    margin: 100px auto 100px auto;
  }
  @media ${below.sm} {
    overflow: scroll;
  }
  table-layout: fixed;
  border-spacing: 0;
`;

const Cell = styled.td`
  padding: 10px;
  font-size: 1.8rem;
  line-height: 1.9;
  border-style: solid;
  border-width: 1.5px;
  border-color: ${props => props.theme.color.grey};
  word-wrap: break-word;
  border-right: ${props => (props.lastCell ? '1.5px solid' : '0')};
  border-bottom: ${props => (props.lastRow ? '1.5px solid' : '0')};
`;

const Table = ({ block, block: { chart } }) => {
  const { rows } = chart;

  return (
    <StyledDiv width={block.width}>
      {rows &&
        rows.map((row, rowIndex) => (
          <tr key={row._key}>
            {row.cells.map((cell, i) => (
              <Cell
                lastCell={i == row.cells.length - 1}
                lastRow={rowIndex == rows.length - 1}
                key={cell}
                dangerouslySetInnerHTML={{ __html: cell }}
              />
            ))}
          </tr>
        ))}
    </StyledDiv>
  );
};

export default Table;
