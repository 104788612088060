import React from 'react';
import styled from 'styled-components';
import { above } from '../styles';

const H3 = styled.h3`
  line-height: 1.38;
  font-size: 3.6rem;

  @media ${above.lg} {
    max-width: 920px;
  }
`;

const SectionSubheading = ({ block }) => {
  return <H3>{block.sectionSubheading}</H3>;
};

export default SectionSubheading;
