import React from 'react';
import styled, { css } from 'styled-components';

// need this to pass styles
const StyledPlay = styled.svg``;

const Play = ({ className }) => (
  <StyledPlay
    className={className}
    width="100"
    height="100"
    viewBox="0 0 100 100"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="50" cy="50" r="48.5" stroke="white" strokeWidth="3" />
    <path
      d="M45.5098 66.5135L45.5098 34.117L61.708 50.3153L45.5098 66.5135Z"
      stroke="white"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </StyledPlay>
);

export default Play;
