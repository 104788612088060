import React from 'react';
import styled from 'styled-components';
import getYouTubeID from 'get-youtube-id';

const RichTextYouTube = ({ url, centered }) => {
  const id = getYouTubeID(url);
  const embedUrl = `https://www.youtube.com/embed/${id}`;

  if (!id) {
    return <div>Missing YouTube URL</div>;
  }
  const YoutubeWrapper = styled.div`
    ${props => (props.centered ? 'text-align: center' : '')};
  `;
  return (
    <YoutubeWrapper centered>
      <iframe
        title="YouTube Embed"
        width="560"
        height="315"
        align="middle"
        src={embedUrl}
        frameBorder="0"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
      />
    </YoutubeWrapper>
  );
};

export default RichTextYouTube;
