import imageUrlBuilder from '@sanity/image-url';
import { get } from 'lodash';
import sanityConfig from './sanityClientConfig';

const builder = imageUrlBuilder(sanityConfig);

export function imageUrlFor(source) {
  return builder.image(source);
}

export function buildImageObj(source = { asset: {} }) {
  if (!get(source, 'asset._ref') && !get(source, 'asset._id')) {
    return {};
  }

  const imageObj = {
    asset: { _ref: source.asset._ref || source.asset._id },
  };

  if (source.crop) imageObj.crop = source.crop;
  if (source.hotspot) imageObj.hotspot = source.hotspot;

  return imageObj;
}
