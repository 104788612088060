import React from 'react';
import styled from 'styled-components';
import { above, below } from '../styles';
import { Container, Paragraph, FluidImg, CTALink } from '../elements';
import checkPropsEquality from '../utils/checkPropsEquality';

const Wrapper = styled.section`
  padding-bottom: 75px;
  padding-top: 75px;

  @media ${above.md} {
    padding-top: 125px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
`;

const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  @media ${above.sm} {
    padding-right: 60px;
  }

  @media ${above.md} {
    padding-top: 20px;
    width: 45%;
  }

  @media ${above.lg} {
    padding-right: 90px;
  }
`;

const TextWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
`;

const ParagraphWrapper = styled.div`
  margin-top: 15px;
  padding-bottom: 30px;
  float: right;
  @media ${above.md} {
    width: 53%;
  }
`;

const LinkWrapper = styled.div`
  margin-top: 30px;
`;

const Heading = styled.h2`
  font-size: 5.2rem;
  line-height: 1.27;
  @media ${above.xl} {
    font-size: 7.5rem;
    line-height: 1.26;
  }
`;

const LogoBlock = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;

  &:after {
    content: '';
    width: 30%;
  }
  @media ${below.md} {
    margin-bottom: 10px;
    justify-content: flex-start;
  }

  @media ${above.md} {
    width: 55%;
  }
`;

const List = styled.ul`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
`;

const ListItem = styled.li`
  margin-bottom: 50px;
  width: 33.33%;
  display: flex;
  align-items: center;
  justify-content: center;

  @media ${below.md} {
    margin-bottom: 0px;
  }
`;

const ImgWrapper = styled.div`
  margin-top: 20px;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50%;
`;

const TechStack = ({ block }) => {
  const { heading, paragraph, ctaUrl, ctaUrlText, partners } = block;

  return (
    <Container>
      <Wrapper>
        <HeaderWrapper>{heading && <Heading>{heading}</Heading>}</HeaderWrapper>
        <LogoBlock>
          <List>
            {partners.map(partner => (
              <ListItem key={partner.id}>
                <ImgWrapper>
                  <FluidImg
                    key={partner.companyLogo._id}
                    asset={partner.companyLogo.asset}
                    alt={partner.name}
                    wrapperStyle={{
                      width: '100%',
                      maxWidth: '200px',
                      maxHeight: '80px',
                    }}
                    imgStyle={{
                      width: 'auto',
                      maxWidth: '100%',
                      left: '50%',
                      top: '50%',
                      transform: 'translate(-50%, -50%)',
                    }}
                  />
                </ImgWrapper>
              </ListItem>
            ))}
          </List>
        </LogoBlock>
        <TextWrapper>
          <ParagraphWrapper>
            <Paragraph text={paragraph} />
            {ctaUrlText != undefined &&
              <LinkWrapper>
                <CTALink text={ctaUrlText} url={ctaUrl} darkMode />
              </LinkWrapper>
            }

          </ParagraphWrapper>
        </TextWrapper>
      </Wrapper>
    </Container>
  );
};

export default React.memo(TechStack, checkPropsEquality);
