import React from 'react';
import styled, { css } from 'styled-components';
import TrackVisibility from 'react-on-screen';
import { FluidImg, Paragraph } from '../elements';
import { below, above } from '../styles';
import PostTeaserCard from './PostTeaserCard';
import ClientCard from './ClientCard';
import TestimonialCard from './TestimonialCard';
import PlainTile from './PlainTile';
import ClientLogo from './ClientLogo';
import EmbeddedVideo from './EmbeddedVideo';
import YouTube from './YouTube';

const Container = styled.div`
  width: 100%;
  margin-bottom: 20px;
  margin-left: ${props => props.rightAlign && 'auto'};
  &:last-child {
    margin-bottom: 20px;
    @media ${above.sm} {
      margin-bottom: 50px;
    }
  }

  @media ${above.sm} {
    /*Container requires a border here in order for the additional margin-bottom to work correctly
    on ImageTile*/
    /* border-bottom: 1px solid transparent; */
    margin-bottom: 50px;
  }

  @media ${above.md} {
    margin-bottom: ${props => (props.lastLeftTile ? '100px' : '50px')};
  }

  @media ${above.xl} {
    ${props =>
      props.lastRightTile &&
      css`
        margin-bottom: 0;
        width: 87%;
      `}
  }

  @media ${above.xxl} {
    ${props =>
      props.lastRightTile &&
      css`
        margin-bottom: 0;
        width: ${props =>
          props.contentType.match(/clientCard|testimonialCard/g)
            ? '87%'
            : '78%'};
      `}
  }
`;

const ParagraphWrapper = styled.div`
  margin: 50px 22px;
  width: 90%;
  font-size: 1.6rem;

  @media ${above.lg} {
    max-width: 646px;
    margin-left: auto;
    margin-top: 100px;
  }
  @media ${above.xl} {
    margin-top: 165px;
  }

  p + p {
    &:not(:last-child) {
      margin-bottom: 3em;
    }
  }
`;
const HeadingText = styled.div`
  font-size: 3.6rem;
  line-height: 1.38em;
  font-weight: bold;
  margin-bottom: 33px;
  margin-top: 50px;
  @media ${below.md} {
    margin-top: 70px;
    margin-bottom: 45px;
  }
`;
const ImageTile = styled.div`
  transition: all 0.5s ease;
  margin-bottom: 0px;
  transform: translateY(${props => (props.isVisible ? '0' : '20')}px);
  opacity: ${props => (props.isVisible ? '1' : '0')};
  @media ${above.md} {
    width: ${props => props.narrowImage && 'width: 77%'};
  }
`;
const Tile = ({ content, className, index, lastLeftTile, lastRightTile }) => (
  <Container
    className={className}
    rightAlign={content.rightAlign && content.rightAlign}
    lastLeftTile={lastLeftTile}
    lastRightTile={lastRightTile}
    contentType={content._type}
    wideDisplay
  >
    {content._type === 'clientCard' && (
      <ClientCard index={index} card={content} />
    )}
    {content._type === 'post' && <PostTeaserCard post={content} />}
    {content._type === 'postPreview' && (
      <PostTeaserCard
        post={content.postReference}
        backgroundColor={content.backgroundColor}
      />
    )}
    {content._type === 'testimonial' && (
      <TestimonialCard testimonial={content} />
    )}
    {content._type === 'paragraphTile' && (
      <ParagraphWrapper key={content._key}>
        {content.heading ? <HeadingText>{content.heading}</HeadingText> : null}
        <Paragraph text={content.text} />
      </ParagraphWrapper>
    )}
    {content._type === 'embeddedVideo' && (
      <TrackVisibility once="true" offset={200} partialVisibility="false">
        <ImageTile>
          <EmbeddedVideo block={content} />
        </ImageTile>
      </TrackVisibility>
    )}
    {content._type === 'youtube' && (
      <TrackVisibility once="true" offset={200} partialVisibility="false">
        <ImageTile>
          <YouTube block={content} />
        </ImageTile>
      </TrackVisibility>
    )}
    {content._type === 'plainTile' && <PlainTile content={content} />}
    {content._type === 'clientLogo' && <ClientLogo content={content} />}
    {content._type === 'imageTile' && (
      <TrackVisibility once="true" offset={200} partialVisibility="false">
        <ImageTile narrowImage={content.narrowImage}>
          <FluidImg
            maxWidth={1400}
            key={content._key}
            asset={content.image.asset}
            alt={content.image.alt}
          />
        </ImageTile>
      </TrackVisibility>
    )}
  </Container>
);

export default Tile;
