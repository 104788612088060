/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gat sbyjs.org/docs/static-query/
 */

import React from 'react';
import PropTypes from 'prop-types';
import { ThemeProvider } from 'styled-components';
import { GlobalStyle } from '../styles';
import theme from '../styles/abstracts/theme';
import Header from './Header';
import Footer from './Footer';
import SEO from './SEO';

const Layout = ({ children, seo }) => (
  <ThemeProvider theme={theme}>
    <>
      <GlobalStyle />
      <SEO seo={seo} />
      <Header />
      <main>{children}</main>
      <Footer />
    </>
  </ThemeProvider>
);

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
