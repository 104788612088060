import React from 'react';
import styled from 'styled-components';
import TrackVisibility from 'react-on-screen';
import CTALink from '../elements/CTALink';
import { above, below } from '../styles';
import checkPropsEquality from '../utils/checkPropsEquality';

const Heading = styled.h3`
  color: ${props => props.theme.color.white};
  text-align: center;
  font-size: 4.6rem;
  line-height: 1.26;
  overflow-wrap: break-word;
  word-wrap: break-word;
  hyphens: auto;
  order: 2;

  @media ${above.md} {
    font-size: 5.2rem;
    line-height: 1.4;
  }

  @media ${below.md} {
    order: 2;
  }
`;

const SubHeading = styled.div`
  font-size: 1.8rem;
  color: ${props => props.theme.color.white};
  margin-top: -20px;
  padding-bottom: 15px;
  text-align: center;
  line-height: 1.5;
  @media ${below.md} {
    order: 1;
    margin-top: 0px;
    font-size: 1.6rem;
    line-height: 1.31;
  }
`;

const HeadingWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const LinkWrapper = styled.div``;

const Container = styled.section`
  background-color: ${props =>
    props.backgroundColor
      ? props.backgroundColor
      : props.theme.color.blue.navy};
  padding: 140px 60px;
  text-align: center;
  ${SubHeading} {
    transition: all 0.5s ease;
    opacity: ${props => (props.isVisible ? '1' : '0')};
    transform: translateY(${props => (props.isVisible ? '0' : '20px')});
  }
  ${Heading} {
    transition: all 0.5s ease;
    transition-delay: 0.2s;
    opacity: ${props => (props.isVisible ? '1' : '0')};
    transform: translateY(${props => (props.isVisible ? '0' : '20px')});
  }
  ${LinkWrapper} {
    transition: all 0.5s ease;
    transition-delay: 0.5s;
    opacity: ${props => (props.isVisible ? '1' : '0')};
    transform: translateY(${props => (props.isVisible ? '0' : '20px')});
  }
  @media ${below.md} {
    padding: 100px 20px;
  }
`;

const CTABlock = ({
  block: { ctaUrl, ctaUrlText, heading, subHeading, backgroundColor },
}) => {
  const headingDisplay = subHeading ? (
    <HeadingWrapper>
      <Heading
        subHeading={subHeading}
        // need this for long word in mobile e.g. "BigCommerce"
        dangerouslySetInnerHTML={{ __html: heading }}
      />
      <SubHeading>{subHeading}</SubHeading>
    </HeadingWrapper>
  ) : (
    <Heading
      subHeading={subHeading}
      // need this for long word in mobile e.g. "BigCommerce"
      dangerouslySetInnerHTML={{ __html: heading }}
    />
  );
  return (
    <TrackVisibility offset={350}>
      <Container backgroundColor={backgroundColor && backgroundColor.hex}>
        {headingDisplay}
        <LinkWrapper>
          <CTALink
            center
            url={'/' + ctaUrl[0].slug.current}
            text={ctaUrlText}
          />
        </LinkWrapper>
      </Container>
    </TrackVisibility>
  );
};

export default React.memo(CTABlock, checkPropsEquality);
