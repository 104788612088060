import React, { useCallback, useRef, useState } from 'react';
import styled from 'styled-components';
import { get } from 'lodash';
import { navigate } from 'gatsby';
import { useSpring, animated } from 'react-spring';
import { imageUrlFor, buildImageObj } from '../utils/image-url';
import { above, below } from '../styles';
import { Paragraph, CTALink } from '../elements';
import checkPropsEquality from '../utils/checkPropsEquality';

const Details = styled.div`
  padding-top: ${props => props.showDetails && '0.5em'};
  color: ${props =>
    props.showDetails ? props.theme.color.white : props.theme.color.blue.navy};
  @media ${below.sm} {
    opacity: ${props => props.showDetails && '1 !important'};
    height: ${props => props.showDetails && '100% !important'};
  }
  @media ${above.md} {
    max-width: 400px;
  }
`;
const Wrapper = styled.div`
  display: flex;
  cursor: ${props => props.hasLink && 'pointer'};
  margin-bottom: ${props => props.stacked && '20px'};
  overflow: hidden;
  transition-duration: 400ms;
  transition-property: transform box-shadow;
  transition-timing-function: ease;
  transform: ${props => (props.showDetails ? 'scale(1.02)' : 'scale(1)')};
  box-shadow: 0 0 60px 0px
    ${props => (props.showDetails ? 'rgba(32,40,70,0.1)' : 'rgba(0,0,0,0)')};
  &&& {
    width: 100%;
    @media ${above.md} {
      width: 40%;
      height: auto;
    }
    @media ${above.xl} {
      width: 26%;
    }
  }
`;

const Card = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 100%;
  min-height: 100%;
  background-color: ${props =>
    props.showDetails ? props.theme.color.green : props.theme.color.white};
  p {
    margin-bottom: 35px;
    margin-top: 20px;
  }

  @media ${below.sm} {
    min-height: ${props => props.mHeight && `${props.mHeight}px`};
  }
`;

const Heading = styled.h3`
  transition: color 400ms;
  color: ${props =>
    props.showDetails ? props.theme.color.white : props.theme.color.blue.navy};
  max-width: 400px;
  font-size: 2.4rem;
  margin-bottom: 0 !important;
  margin-top: 0 !important;

  @media ${above.lg} {
    font-size: 3rem;
  }

  @media ${above.xxl} {
    font-size: 3.6rem;
  }
`;

const IconWrapper = styled.div`
  width: 100px;
  height: auto;
  margin-bottom: 10px;

  @media ${above.xxl} {
    width: 130px;
    margin-right: calc(100% - 130px);
  }

  img {
    width: 100%;
    max-width: 100%;
  }
`;

const Content = styled.div`
  display: flex;
  padding: 40px;
  flex-wrap: wrap;
  height: ${props => props.showDetails && '100%'};

  @media ${above.xl} {
    padding: 60px;
  }
`;

const StyledDiv = styled.div`
  padding-top: ${props => (props.showDetails ? '20%' : '50%')};
`;

const StyledCTALink = styled(CTALink)`
  z-index: 10;
`;

const CarouselCard = ({
  className,
  stacked,
  showDetails,
  setActiveIndex,
  id,
  card: { icon, heading, paragraph, ctaUrl, ctaUrlText },
}) => {
  // Need explicit height to avoid card animation jumping
  const [cardHeight, setCardHeight] = useState(0);

  // https://reactjs.org/docs/hooks-faq.html#how-can-i-measure-a-dom-node
  const cardRef = useCallback(node => {
    // height should always match width (square card)
    if (node !== null && cardHeight !== node.scrollWidth) {
      setCardHeight(node.scrollWidth);
    }
  });

  const iconRef = useRef();
  const headingRef = useRef();
  const detailsRef = useRef();

  const handleShowDetails = () => {
    if (paragraph || ctaUrlText) {
      setActiveIndex(id);
    }
  };

  const handleHideDetails = () => setActiveIndex(null);

  const handleToggleDetails = () => {
    if (paragraph || ctaUrlText) {
      if (showDetails) {
        setActiveIndex(null);
      } else {
        setActiveIndex(id);
      }
    }
  };

  const handleNavigate = url => {
    if (window && window.innerWidth > 700) {
      navigate(url);
    }
  };

  const AnimatedCard = animated(Card);
  const AnimatedIcon = animated(IconWrapper);
  const AnimatedDetails = animated(Details);

  const animatedHeight =
    get(detailsRef, 'current.scrollHeight') +
    get(headingRef, 'current.scrollHeight') +
    get(iconRef, 'current.scrollHeight') +
    150;

  const cardAnimationProps = useSpring({
    height: showDetails ? `${animatedHeight}px` : `${cardHeight}px`,
  });

  const iconAnimationProps = useSpring({
    //opacity: showDetails ? 0 : 1,
    //transform: `translateY(${showDetails ? -100 : 0})`,
    //height: showDetails ? 0 : 'auto',
    config: { tension: 1000 },
  });

  const detailsAnimationProps = useSpring({
    opacity: showDetails ? 1 : 0,
    height: showDetails ? get(detailsRef, 'current.scrollHeight') : 0,
  });

  const url = ctaUrl && get(ctaUrl[0], 'slug.current');

  return (
    // extra Wrapper div needed for Swiper to work
    <Wrapper
      hasLink={url ? 1 : 0}
      className={className}
      onMouseEnter={handleShowDetails}
      onMouseLeave={handleHideDetails}
      onBlur={handleHideDetails}
      onClick={() => handleNavigate('/' + url)}
      // Programmatically navigate if there's link. Don't want
      // to wrap the entire card as anchor tag for SEO reasons
      stacked={stacked}
      ref={cardRef}
      showDetails={showDetails}
    >
      <AnimatedCard
        mHeight={cardHeight}
        style={cardAnimationProps}
        showDetails={showDetails}
      >
        <Content>
          <StyledDiv
            onTouchStart={handleToggleDetails}
            onTouchCancel={handleToggleDetails}
            showDetails={showDetails}
          >
            <AnimatedIcon style={iconAnimationProps} ref={iconRef}>
              <img
                src={imageUrlFor(buildImageObj(icon))
                  .width(130)
                  .auto('format')
                  .url()}
                alt={icon.alt}
              />
            </AnimatedIcon>

            <Heading ref={headingRef} showDetails={showDetails}>
              {heading}
            </Heading>
          </StyledDiv>
          {(paragraph || ctaUrlText) && (
            <AnimatedDetails
              style={detailsAnimationProps}
              ref={detailsRef}
              showDetails={showDetails}
            >
              {paragraph && (
                <div
                  onTouchStart={handleToggleDetails}
                  onTouchCancel={handleToggleDetails}
                >
                  <Paragraph text={paragraph} />
                </div>
              )}
              {ctaUrl && ctaUrlText && (
                <StyledCTALink
                  css={`
                    //font-weight: bold;
                  `}
                  darkMode={!showDetails}
                  url={'/' + url}
                  text={ctaUrlText}
                />
              )}
            </AnimatedDetails>
          )}
        </Content>
      </AnimatedCard>
    </Wrapper>
  );
};

export default React.memo(CarouselCard, checkPropsEquality);
