import React from 'react';
import styled from 'styled-components';
import BlockContent from '@sanity/block-content-to-react';
import { FluidImg } from '../elements';
import checkPropsEquality from '../utils/checkPropsEquality';
import RichTextYouTube from './RichTextYoutube';
import Table from './Table';
import { above } from '../styles';

const RichTextWrapper = styled.div`
  margin: auto 20px auto 20px;

  @media ${above.md} {
    margin: auto;
    max-width: ${props => props.width};
  }
  a {
    color: ${props => props.theme.color.green} !important;
  }

  h2 {
    line-height: 1.26;
    font-size: 5.2rem;

    @media ${above.lg} {
      font-size: 7.5rem;
    }
  }

  h3 {
    line-height: 1.38;
    font-size: 3.6rem;
    margin-bottom: 65px;

    @media ${above.lg} {
      margin-bottom: 45px;
    }
  }

  h6 {
    font-size: 1.8rem;
    @media ${above.md} {
      font-size: 2.2rem;
    }
    line-height: 1.5;
    margin-bottom: 28px;
  }

  blockquote {
    line-height: 2;
    margin-left: 0;
    margin-right: 0;
  }
  blockquote:before {
    color: #ccc;
    font-family: 'Georgia';
    content: '“';
    font-size: 5em;
    line-height: 0.1em;
    margin-right: 0.25em;
    vertical-align: -0.4em;
  }
  blockquote:after {
    color: #ccc;
    font-family: 'Georgia';
    content: '”';
    font-size: 3em;
    line-height: 0.1em;
    margin-left: 0.25em;
    vertical-align: -0.4em;
  }
  p {
    font-size: 1.8rem;
  }

  ol {
    list-style-type: decimal;
    li {
      list-style-position: outside;
      margin-left: 32px;
      padding-left: 18px;
      line-height: 2;
    }
  }
  ul {
    list-style-type: disc;
    li {
      list-style-position: outside;
      margin-left: 6px;
      padding-left: 0px;
      line-height: 2;
      @media ${above.md} {
        margin-left: 32px;
      }
    }
  }
  }
`;

const RichText = ({ block, className }) => {
  
  const serializer = {
    types: {
      defaultImage: props => (
        <FluidImg
          asset={props.node.asset}
          alt={props.node.alt}
          wrapperStyle={{
            maxWidth: props.node.asset.metadata.dimensions.width,
            margin: '0 auto',
          }}
        />
      ),
      youtube: props => (
        <RichTextYouTube url={props.node.url} centered={props.node.centered} />
      ),
      tableBlock: props => <Table block={props.node} />,
    },
    marks: {
      internalLink: ({ mark, children }) => {
        const slug = mark.reference.slug.current ? mark.reference.slug.current : null;
        const prefix = mark.reference._type == 'post' ? '/articles' : "" 
        const href = `${prefix}/${slug}` 
        return <a href={href}>{children}</a>;
      },
    },
  };

  return (
    <RichTextWrapper className={className} width={block.width}>
      <BlockContent blocks={block.content} serializers={serializer} />
    </RichTextWrapper>
  );
};

export default React.memo(RichText, checkPropsEquality);
