import React from 'react';
import styled from 'styled-components';
import Logo from './Logo';
import { below, above } from '../styles';

const List = styled.ul`
  flex-wrap: wrap;
  display: flex;
  width: 100%;
  justify-content: space-between;
  overflow: hidden;
  margin-left: auto;
  margin-right: auto;
  max-width: 500px;

  @media ${above.lg} {
    max-width: 550px;
    padding: 60px;
  }
  @media ${below.md} {
    padding: 20px 0px;
  }
`;

const ListItem = styled.li`
  margin-bottom: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 20px;
  padding-right: 20px;

  @media ${above.md} {
    width: 45%;
  }
  @media ${below.md} {
    width: 35%;
    width: 50%;
    margin-bottom: 0px;
  }
`;
const ImgWrapper = styled.div`
  margin-top: 20px;
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
  padding-top: 30px;
  padding-bottom: 30px;

  @media ${above.sm} {
    padding-top: 0;
    padding-bottom: 60px;
  }
  @media ${above.lg} {
    /** Last row - last 2 elements */
    &:nth-last-child(-n + 2) {
      padding-bottom: 0;
    }
  }
  @media ${below.md} {
    margin-top: 0px;
    margin-bottom: 0px;
  }
`;

const SLogo = styled(Logo)`
  width: 100px;
  @media ${above.md} {
    width: 130px;
  }
`;

const ClientLogo = content => (
  <List>
    {content.content.clients.map(client => (
      <ListItem key={client.id}>
        <ImgWrapper>
          <SLogo logo={client} />
        </ImgWrapper>
      </ListItem>
    ))}
  </List>
);
export default ClientLogo;
