import React from 'react';
import styled from 'styled-components';
import TrackVisibility from 'react-on-screen';
import { FluidImg } from '../elements';
import checkPropsEquality from '../utils/checkPropsEquality';
import { above } from '../styles';

const ImageWrapper = styled.div`
  transition: all 0.5s ease;
  transform: translateY(${props => (props.isVisible ? '0' : '20')}px);
  opacity: ${props => (props.isVisible ? '1' : '0')};
  width: 100%;
  margin-top: 0px;
  margin-bottom: 0px;
  padding-right: 0px;
  padding-left: 0px;

  @media ${above.md} {
    margin-top: 50px;
    margin-bottom: 50px;
  }
`;

const ImageOnly = ({ block: { image, fullWidth } }) => (
  <TrackVisibility once="true" partialVisibility="true">
    <ImageWrapper>
      <FluidImg
        asset={image.asset}
        alt={image.alt}
        imgStyle={{
          alignSelf: 'center',
          height: '100%',
        }}
        wrapperStyle={{
          maxWidth: fullWidth ? 'none' : '450px',
          margin: '0 auto',
        }}
      />
    </ImageWrapper>
  </TrackVisibility>
);

export default React.memo(ImageOnly, checkPropsEquality);
