import { isEqual } from 'lodash';

export default function deepCompareEquality(prevProps, nextProps) {
  /*
  return true if passing nextProps to render would return
  the same result as passing prevProps to render,
  otherwise return false
  */

  return isEqual(prevProps, nextProps);
}
