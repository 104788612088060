import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { graphql, useStaticQuery } from 'gatsby';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';
import { InfiniteScroll } from 'react-simple-infinite-scroll-patched';
import { Container } from '../elements';
import { below, above } from '../styles';
import Tile from './Tile';

const OuterWrapper = styled.div`
  width: 100%;
  display: flex;
  @media ${below.md} {
    flex-direction: column;
  }
`;
const MenuContainerDiv = styled.div`
  list-style-type: none;
  margin-right: 85px;
  text-align: left;
  display: none;
  @media ${above.md} {
    display: flex;
    flex-direction: column;
    margin-right: 5px;
    min-width: 25%;
  }
  @media ${above.lg} {
    margin-right: 45px;
    margin-top: 25px;
  }
  @media ${above.xl} {
    margin-right: 65px;
  }
`;
const MenuContainer = styled.ul`
  position: sticky;
  top: 60px;
  margin-bottom: 60px;
`;

const CategoryText = styled.li`
  font-size: 1.8rem;
  padding-right: 10px;
  margin-right: 10px;
  cursor: pointer;
  line-height: 45px;
  white-space: nowrap;
  color: ${props => props.theme.color.blue.lightNavy};
  &:hover {
    background-color: ${props => props.theme.color.grey.superLight};
    padding-left: 10px;
    margin-right: 0px;
  }
`;
const SelectedCategoryText = styled(CategoryText)`
  font-weight: bold;
`;
const TileContainer = styled.div`
  @media ${above.md} {
    margin-top: -85px;
    overflow: hidden;
  }
  @media ${above.lg} {
    margin-top: -60px;
  }
`;
const TeaserWrapper = styled.div`
  ${props => props.bgColor && `background-color: ${props.bgColor}`};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @media ${above.md} {
    flex-direction: row;
    padding-bottom: 20px;
    margin-bottom: 70px;
    /*min width prevents container from collapsing when there's nothing in one column*/
    ${props => (props.singlePost ? 'min-width: 605px' : '')}
  }
  @media ${above.lg} {
    ${props => (props.singlePost ? 'min-width: 800px' : '')}
  }
  @media ${above.xl} {
    ${props => (props.singlePost ? 'min-width: 1000px' : '')}
  }
`;
const LeftCol = styled.div`
  width: 100%;
  margin-bottom: 20px;

  @media ${above.md} {
    width: calc(50% - 25px);
    margin-top: 95px;
    margin-bottom: 0;
  }
`;
const RightCol = styled.div`
  width: 100%;
  @media ${above.md} {
    width: calc(50% - 25px);
  }
`;

const BackgroundWrapper = styled.div`
  ${props =>
    props.backgroundColor && `background-color: ${props.backgroundColor}`};
`;
const MobileMenuDropdown = styled.div`
  height: 80px;
  padding-left: 30px;
  padding-right: 30px;
  width: 100%;
  background-color: ${props => props.theme.color.white};
  align-items: center;
  display: flex;

  justify-content: space-between;
`;
const DropdownText = styled.div`
  font-size: 1.8rem;
  line-height: 1em;
  font-weight: bold;
`;
const DropdownArrow = styled(FaChevronDown)``;
const ArrowUp = styled(FaChevronUp)``;

const ExpandedContainer = styled.ul`
  display: flex;
  flex-direction: column;
  padding-left: 30px;
  padding-bottom: 20px;
  background-color: ${props => props.theme.color.white};
  max-height: 450px;
  position: absolute;
  z-index: 5;
  overflow: scroll;
  width: 100%;
  ${props => (props.dropdownOpen ? '' : 'display: none')}
`;
const MobileMenuWrapper = styled.div`
  position: sticky;
  top: 0;
  z-index: 5;
  margin-bottom: 23px;
  @media ${above.md} {
    display: none;
  }
`;
const PostNavigation = styled.div`
  display: flex;
  justify-content: space-between;
  width: 60%;
  margin: auto;
  padding-bottom: 30px;
`;
const NavLink = styled.div`
  font-size: 24px;
  cursor: pointer;
`;
const ColumnSpacer = styled.div`
  @media ${above.md} {
    margin-top: 95px;
  }
`;
const FilteredTiles = ({ block }) => {
  //Parse all categories from Sanity and use them to render filters
  const data = useStaticQuery(graphql`
    query PostCategoriesQuery {
      postCategories: allSanityPostCategory {
        edges {
          node {
            title
          }
        }
      }
      posts: allSanityPost {
        edges {
          node {
            title
            slug {
              current
            }
            author {
              name
              image {
                alt
                asset {
                  id
                  url
                  assetId
                  _id
                }
              }
            }
            categories {
              title
            }
            publishedAt
            _rawContentBlocks(resolveReferences: { maxDepth: 5 })
          }
        }
      }
    }
  `);
  const { postCategories, posts } = data;
  return (
    <FilteredTilesInner
      posts={posts}
      postCategories={postCategories}
      block={block}
    />
  );
};

const FilteredTilesInner = props => {
  const [selectedCategory, setSelectedCategory] = useState('All');
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [displayPage, setDisplayPage] = useState(1);
  const [postColumns, setPostColumns] = useState([]);
  const [displayedPosts, setDisplayedPosts] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [hasMore, setHasMore] = useState(false);

  const allDisplay =
    selectedCategory === 'All' ? (
      <SelectedCategoryText>All</SelectedCategoryText>
    ) : (
      <CategoryText
        onClick={() => {
          setSelectedCategory('All');
          setLoaded(false);
        }}
      >
        All
      </CategoryText>
    );
  const allDisplayMobile =
    selectedCategory === 'All' ? (
      <SelectedCategoryText>All</SelectedCategoryText>
    ) : (
      <CategoryText
        onClick={() => {
          setSelectedCategory('All');
          setDropdownOpen(false);
          setLoaded(false);
        }}
      >
        All
      </CategoryText>
    );
  const menuDisplay = props.postCategories.edges.map(category =>
    selectedCategory === category.node.title ? (
      <SelectedCategoryText>{category.node.title}</SelectedCategoryText>
    ) : (
      <CategoryText
        key={category.node.title}
        onClick={() => {
          setLoaded(false);
          setSelectedCategory(category.node.title);
        }}
      >
        {category.node.title}
      </CategoryText>
    )
  );
  const menuDisplayMobile = props.postCategories.edges.map(category =>
    selectedCategory === category.node.title ? (
      <SelectedCategoryText key={category.node.title}>
        {category.node.title}
      </SelectedCategoryText>
    ) : (
      <CategoryText
        key={category.node.title}
        onClick={() => {
          setSelectedCategory(category.node.title);
          setDropdownOpen(false);
          setLoaded(false);
        }}
      >
        {category.node.title}
      </CategoryText>
    )
  );
  const arrowDisplay = dropdownOpen ? <ArrowUp /> : <DropdownArrow />;
  const mobileMenuDisplay = dropdownOpen ? (
    <MobileMenuDropdown onClick={() => setDropdownOpen(false)}>
      <DropdownText>{selectedCategory}</DropdownText>
      {arrowDisplay}
    </MobileMenuDropdown>
  ) : (
    <MobileMenuDropdown onClick={() => setDropdownOpen(true)}>
      <DropdownText>{selectedCategory}</DropdownText>
      {arrowDisplay}
    </MobileMenuDropdown>
  );
  const mobileMenu = (
    <MobileMenuWrapper>
      {mobileMenuDisplay}
      <ExpandedContainer dropdownOpen={dropdownOpen}>
        {allDisplayMobile}
        {menuDisplayMobile}
      </ExpandedContainer>
    </MobileMenuWrapper>
  );
  const parsePostData = () => {
    //Filter posts by selected category for display
    const filteredPosts = [];
    props.posts.edges.forEach(post => {
      const wrapperObj = {
        _type: 'postPreview',
        postReference: post.node,
        _key: post.node.id,
        publishedAt: Date.parse(post.node.publishedAt),
      };
      if (selectedCategory !== 'All') {
        if (
          post.node.categories &&
          post.node.categories.title === selectedCategory
        ) {
          filteredPosts.push(wrapperObj);
        }
      } else {
        filteredPosts.push(wrapperObj);
      }
    });
    // //Sort posts by publication date with most recent first
    filteredPosts.sort((a, b) => (a.publishedAt > b.publishedAt ? -1 : 1));

    //Sort filteredPosts into initial left and right column arrays
    const leftColPosts = [];
    const rightColPosts = [];
    filteredPosts.forEach((post, i) => {
      if (i % 2 == 0) {
        leftColPosts.push(post);
      } else {
        rightColPosts.push(post);
      }
    });

    let currentDisplayPostsLeft = [];
    let currentDisplayPostsRight = [];

    if (leftColPosts.length > 3) {
      leftColPosts.forEach((post, i) => {
        if (i <= 2) {
          currentDisplayPostsLeft.push(post);
        }
      });
    } else {
      currentDisplayPostsLeft = leftColPosts;
    }
    if (rightColPosts.length > 3) {
      rightColPosts.forEach((post, i) => {
        if (i <= 2) {
          currentDisplayPostsRight.push(post);
        }
      });
    } else {
      currentDisplayPostsRight = rightColPosts;
    }
    let leftRightColumns = [];
    let displayLeftRightColumns = [];
    displayLeftRightColumns.push(currentDisplayPostsLeft);
    displayLeftRightColumns.push(currentDisplayPostsRight);
    leftRightColumns.push(leftColPosts);
    leftRightColumns.push(rightColPosts);
    if (displayLeftRightColumns[0].length == leftRightColumns[0].length) {
      setHasMore(false);
    } else if (displayLeftRightColumns[0].length < leftRightColumns[0].length) {
      setHasMore(true);
    }
    setPostColumns(leftRightColumns);
    setDisplayedPosts(displayLeftRightColumns);
    setLoaded(true);
  };
  if (!loaded) {
    parsePostData();
  }
  const fetchMorePosts = () => {
    //Load next 6 posts
    let updatedDisplayPostsLeft = [];
    let updatedDisplayPostsRight = [];

    if (postColumns[0].length > 3) {
      postColumns[0].forEach((post, i) => {
        if (i <= displayedPosts[0].length + 2) {
          updatedDisplayPostsLeft.push(post);
        }
      });
    } else {
      updatedDisplayPostsLeft = postColumns[0];
    }
    if (postColumns[1].length > 3) {
      postColumns[1].forEach((post, i) => {
        if (i <= displayedPosts[1].length + 2) {
          updatedDisplayPostsRight.push(post);
        }
      });
    } else {
      updatedDisplayPostsRight = postColumns[1];
    }
    let leftRightColumns = [];
    leftRightColumns.push(updatedDisplayPostsLeft);
    leftRightColumns.push(updatedDisplayPostsRight);
    if (updatedDisplayPostsLeft.length == postColumns[0].length) {
      setHasMore(false);
    } else if (
      updatedDisplayPostsLeft.length < postColumns[0].length &&
      hasMore == false
    ) {
      setHasMore(true);
    }
    setDisplayedPosts(leftRightColumns);
  };
  if (loaded) {
    return (
      <BackgroundWrapper
        backgroundColor={
          props.block.backgroundColor && props.block.backgroundColor.hex
        }
      >
        <Container>
          <OuterWrapper>
            <MenuContainerDiv></MenuContainerDiv>
            <TileContainer>
              <InfiniteScroll
                threshold={300}
                onLoadMore={fetchMorePosts}
                hasMore={hasMore}
              >
                <TeaserWrapper
                  singlePost={
                    displayedPosts[0].length + displayedPosts[1].length == 1
                  }
                >
                  <LeftCol>
                    {displayedPosts[0].map(b => (
                      // some blocks only have key but not id, and vice versa
                      <Tile key={b._key || b._id} content={b} />
                    ))}
                  </LeftCol>
                  <RightCol>
                    {displayedPosts[1].map(b => (
                      // some blocks only have key but not id, and vice versa
                      <Tile key={b._key || b._id} content={b} />
                    ))}
                  </RightCol>
                </TeaserWrapper>
              </InfiniteScroll>
            </TileContainer>
          </OuterWrapper>
        </Container>
      </BackgroundWrapper>
    );
  } else {
    return (
      <BackgroundWrapper
        backgroundColor={
          props.block.backgroundColor && props.block.backgroundColor.hex
        }
      >
        {mobileMenu}
        <Container>
          <OuterWrapper>
            <MenuContainerDiv>
              <MenuContainer>
                {allDisplay}
                {menuDisplay}
              </MenuContainer>
            </MenuContainerDiv>
            <TileContainer>Loading...</TileContainer>
          </OuterWrapper>
        </Container>
      </BackgroundWrapper>
    );
  }
};

export default FilteredTiles;
