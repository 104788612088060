import React from 'react';
import styled from 'styled-components';
import { above } from '../styles';

const H4 = styled.h4`
  font-size: 2.2rem;
  line-height: 1.8;
  margin-bottom: 28px;

  @media ${above.md} {
    font-size: 2.2rem;
  }

  &:last-child {
    margin-bottom: 0;
  }
`;

const SectionDetailedSubheading = ({ block }) => {
  return <H4>{block.sectionDetailedSubheading}</H4>;
};

export default SectionDetailedSubheading;
