// TODO Potentially consolidate rendering logic with ContentBlock into this one

import React from 'react';
import LogoGrid from './LogoGrid';
import ImageOnly from './ImageOnly';
import IconTextList from './IconTextList';
import SectionHeading from './SectionHeading';
import SectionSubheading from './SectionSubheading';
import SectionDetailedSubheading from './SectionDetailedSubheading';
import SectionParagraph from './SectionParagraph';
import RichText from './RichText';
import YouTube from './YouTube';
import EmbeddedVideo from './EmbeddedVideo';

const BlockRenderer = ({ block, className, nested }) => {
  switch (block._type) {
    case 'logoGrid':
      return <LogoGrid block={block} className={className} />;
    case 'imageOnly':
      return <ImageOnly block={block} className={className} />;
    case 'iconTextList':
      return (
        <IconTextList block={block} nested={nested} className={className} />
      );
    case 'sectionHeading':
      return <SectionHeading block={block} className={className} />;
    case 'sectionSubheading':
      return <SectionSubheading block={block} className={className} />;
    case 'sectionDetailedSubheading':
      return <SectionDetailedSubheading block={block} className={className} />;
    case 'sectionParagraph':
      return <SectionParagraph block={block} className={className} />;
    case 'richText':
      return <RichText block={block} className={className} />;
    case 'youtube':
      return <YouTube key={block._key} block={block} />;
    case 'embeddedVideo':
      return <EmbeddedVideo key={block._key} block={block} />;
    default:
      return null;
  }
};

export default BlockRenderer;
