import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import Swiper from 'swiper';
import 'swiper/dist/css/swiper.min.css';
import { above, below } from '../styles';
import { Paragraph, CTALink } from '../elements';
import checkPropsEquality from '../utils/checkPropsEquality';

const Wrapper = styled.section`
  text-align: center;
  padding-top: 75px;
  padding-bottom: 75px;
  background-color: ${props => props.theme.color.grey.superLight};
  height: 100%;
  @media ${above.md} {
    display: flex;
    justify-content: space-between;
    padding-top: 115px;
    padding-bottom: 115px;
  }
`;

const Heading = styled.h2`
  font-size: 3.6rem;
  line-height: 1.39;
  text-align: left;
  @media ${below.md} {
    padding-right: 60px;
    font-size: 2.4rem;
    line-height: 1.42;
  }
`;

const Heading2 = styled.h2`
  font-size: 2.6rem;
  line-height: 1.38;
  text-align: left;
  @media ${below.md} {
    padding-right: 60px;
    font-size: 2.2rem;
    line-height: 1.42;
  }
`;

const ContentWrapper = styled.div`
  min-width: 100%;
  @media ${below.md} {
    padding-right: 0px;
    padding-left: 0px;
    overflow: scroll;
  }
  @media ${above.lg} {
    padding-right: 100px;
    padding-left: 100px;
  }
`;

const LogoWrapper = styled.div`
  /* box-sizing: border-box; */
  position: relative;
  padding: 0 0 30px;
  opacity: 0.6;
  filter: grayscale(1);
  cursor: pointer;

  && {
    /* explicit height to set the active underline */
    height: 50px;
    padding-bottom: 20px;

    img {
      height: 50px !important;
      object-fit: contain !important;
      opacity: 1 !important;
    }

    @media ${above.sm} {
      padding-bottom: 40px;
    }

    @media ${below.md} {
      width: ${props => props.slideWidth}px;
    }

    @media ${above.md} {
      height: 75px;
      box-sizing: border-box;
      padding-left: 25px;
      padding-right: 25px;
    }
  }

  &:hover {
    &::after {
      transform: translateY(0%);
    }
  }

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: ${props => props.theme.color.green};
    height: 4px;
    transform: translateY(100%);
    transition: 400ms;
  }

  &.swiper-slide-active {
    opacity: 1;
    filter: grayscale(0);
    &::after {
      transform: translateY(0);
    }
  }
`;

const ImageWrapper = styled.div`
  img {
    width: 100%;
  }
`;

const Content = styled.div`
  box-sizing: border-box;
  min-width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding-left: 28px;
  padding-right: 28px;

  @media ${above.sm} {
    padding-left: 50px;
    padding-right: 50px;
  }
  @media ${above.md} {
    width: 100%;
  }
  @media ${above.lg} {
    padding-left: 100px;
    padding-right: 100px;
  }
  @media ${above.xl} {
    padding-left: 140px;
    padding-right: 140px;
  }
  @media ${above.xxl} {
    padding-left: 240px;
    padding-right: 240px;
  }
`;

const Thumbs = styled.div`
  max-width: 900px;
  padding-left: 28px;
  padding-right: 28px;

  && {
    margin: 0 auto 20px;
    border-bottom: 1px solid ${props => props.theme.color.grey.medium};
  }

  @media ${above.sm} {
    padding-left: 50px;
    padding-right: 50px;
  }
  @media ${above.md} {
    padding-left: 0;
    padding-right: 0;
  }
`;

const ParagraphWrapper = styled.div`
  width: 45%;
  display: inline-block;
  text-align: left;
  font-size: 1.8rem;
  line-height: 1.89;
  padding-top: 100px;
  @media ${below.md} {
    width: 100%;
    padding-top: 80px;
    font-size: 1.6rem;
    line-height: 1.88;
  }
`;

const StyledParagraphWrapper = styled(ParagraphWrapper)`
  padding-top: 70px;
  li {
    list-style-type: disc;
    list-style-position: outside;
    margin-left: 16px;
    margin-bottom: 6px;
  }

  p {
    margin-bottom: 0;
    line-height: 1.5;
  }

  @media ${below.md} {
    width: 100%;
    padding-top: 80px;
  }
`;

const StyledCTALink = styled(CTALink)`
  margin-top: 25px;
  text-decoration: none;
  color: inherit;
`;

const PartnerCarousel = ({ block, block: { partners } }) => {
  if (!block) {
    return null;
  }

  useEffect(() => {
    var galleryThumbs = new Swiper('.gallery-thumbs', {
      spaceBetween: 50,
      slidesPerView: 3,
      watchSlidesVisibility: true,
      watchSlidesProgress: true,
      touchRatio: 0,
      loop: true,
      breakpoints: {
        600: {
          slidesPerView: 'auto',
          spaceBetween: 50,
          loopAdditionalSlides: 1,
          loop: true,
          centeredSlides: true,
        },
      },
    });

    var galleryTop = new Swiper('.gallery-main', {
      spaceBetween: 30,
      thumbs: {
        swiper: galleryThumbs,
      },
      on: {
        slideChange: function() {
          galleryThumbs.slideTo(this.activeIndex);
          Array.from(galleryThumbs.slides).map((slide, index) => {
            slide.classList.remove('swiper-slide-active');
            if (index === this.activeIndex) {
              slide.classList.add('swiper-slide-active');
            }
          });
        },
      },
    });

    return () => {
      galleryThumbs.destroy();
      galleryTop.destroy();
    };
  }, []);

  return (
    <Wrapper>
      <ContentWrapper>
        <Thumbs className="swiper-container gallery-thumbs">
          <div className="swiper-wrapper">
            {partners &&
              partners.map(partner => (
                <LogoWrapper
                  className="swiper-slide"
                  key={partner._id}
                  slideWidth={
                    partner.companyLogo.asset.metadata.dimensions.aspectRatio *
                    45
                  }
                  style={{}}
                >
                  <ImageWrapper>
                    <img
                      src={partner.companyLogo.asset.url}
                      alt={partner.companyLogo.alt}
                    />
                  </ImageWrapper>
                </LogoWrapper>
              ))}
          </div>
        </Thumbs>
        <div className="swiper-container gallery-main">
          <div className="swiper-wrapper">
            {partners &&
              partners.map(partner => (
                <Content key={partner.id} className="swiper-slide">
                  <ParagraphWrapper>
                    <Heading>{partner.name}</Heading>
                    <Paragraph text={partner.paragraph} />
                  </ParagraphWrapper>
                  <StyledParagraphWrapper>
                    <Heading2>Key Features:</Heading2>
                    <Paragraph text={partner.keyFeatures} />
                    <StyledCTALink
                      darkMode
                      text={'Find out more about ' + partner.name}
                      url={'/ecommerce/' + `${partner.slug.current}`}
                    />
                  </StyledParagraphWrapper>
                </Content>
              ))}
          </div>
        </div>
      </ContentWrapper>
    </Wrapper>
  );
};

export default React.memo(PartnerCarousel, checkPropsEquality);
