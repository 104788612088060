import { graphql, useStaticQuery, Link } from 'gatsby';
import React, { useState } from 'react';
import styled, { keyframes, css } from 'styled-components';
import { FaChevronLeft } from 'react-icons/fa';
import { IoMdClose } from 'react-icons/io';
import { Match } from '@reach/router';
import { above } from '../styles';
import { imageUrlFor, buildImageObj } from '../utils/image-url';

const MobileWrapper = styled.div`
  padding-top: 20px;
  padding-bottom: 15px;
  display: flex;
  justify-content: space-between;
  min-height: 68px;
`;
const HamburgerIcon = styled.div`
  display: flex;
  cursor: pointer;
  flex-direction: column;
  margin-top: -5px;
`;
const Line = styled.div`
  height: 2px;
  width: 32px;
  background-color: ${props => props.theme.color.blue.lightNavy};
  margin: 4px 0;
`;
const fadeInOpacity = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

const MenuTransition = styled.div`
  opacity: 1;
  animation-name: ${fadeInOpacity};
  animation-iteration-count: 1;
  animation-timing-function: ease-in;
  animation-duration: 0.4s;
`;
const MenuTransition2 = styled(MenuTransition)``;
const Slide = styled.div`
  transform: -100%;
  transform: ${props =>
    props.menuOpen ? 'translateX(0)' : 'translateX(-100%)'};

  transition: all 0.3s ease-in-out;
`;

const MobileMenu = styled(Slide)`
  background-color: ${props => props.theme.color.blue.lightNavy};
  position: absolute;
  z-index: 21;
  width: 98vw;
  margin-left: -28px;
  padding-left: 30px;
  height: 100%;
  display: flex;
  flex-direction: column;
  @media ${above.sm} {
    margin-left: -50px;
  }
  @media ${above.md} {
    margin-left: -28px;
  }
`;

const MenuHeader = styled(MobileWrapper)`
  padding-right: 20px;
  align-items: center;
`;
const MenuNavigation = styled.ul`
  margin-top: 65px;
`;

const StyledLi = styled.li`
  color: ${props => props.theme.color.white};
  cursor: pointer;
  margin-bottom: ${props => (props.bold ? '35px' : '25px')};
  font-size: ${props => (props.bold ? '2.2rem' : '2.0rem')};
  font-weight: ${props => (props.bold ? 'normal' : 'normal')};
`;

const ArrowWrapper = styled(StyledLi)`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const StyledLink = styled(Link)`
  color: ${props => props.theme.color.white};

  ${props =>
    props.current &&
    css`
      background-image: linear-gradient(
        ${props => props.theme.color.green},
        ${props => props.theme.color.green}
      );
      background-position: 100% 87%;
      background-repeat: no-repeat;
      background-size: 100% 5px;
    `}

  &:visited {
    color: ${props => props.main === 'true' && props.theme.color.white};
  }
`;

const StyledLogo = styled(Link)`
  display: inline-block;
  width: 75px;

  img {
    width: 100%;
  }
  &:hover {
    &:after {
      width: 0;
    }
  }
`;
const StyledLogoMobile = styled(StyledLogo)`
  width: 30px;
`;

const LeftArrow = styled(FaChevronLeft)`
  color: ${props => props.theme.color.white};
  font-size: 1.8rem;
`;
const CloseMenu = styled(IoMdClose)`
  fill: ${props => props.theme.color.white};
  font-size: 3.2rem;
  cursor: pointer;
`;

const MobileHeader = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [menuFocus, setMenuFocus] = useState('main');

  const isSSR = typeof window === 'undefined';

  const data = useStaticQuery(graphql`
    query MobileHeaderQuery {
      companyInfo: sanityCompanyInfo {
        id
        logo: _rawCompanySecondaryLogo(resolveReferences: { maxDepth: 3 })
        logoDark: _rawCompanySecondaryLogoDark(
          resolveReferences: { maxDepth: 3 }
        )
      }
      menu: sanityMenu {
        mainMenu {
          displayName
          pageRef {
            slug {
              current
            }
            title
          }
          expandedItems {
            slug {
              current
            }
            title
          }
        }
      }
    }
  `);

  const {
    companyInfo,
    menu: { mainMenu },
  } = data;

  const { logo, logoDark } = companyInfo;

  const menuItems = mainMenu.map(menuItem => {
    const slug = menuItem.pageRef.slug.current;

    //Check if menu item has a secondary menu
    return menuItem.expandedItems.length ? (
      <StyledLi
        bold
        key={menuItem.id}
        onClick={() => setMenuFocus(menuItem.displayName)}
      >
        {menuItem.displayName}
      </StyledLi>
    ) : (
      <StyledLi bold key={menuItem.id}>
        {/* bypass Netlify build issue - window not defined */}
        {!isSSR && (
          <Match path={`/${slug}`}>
            {props => (
              <StyledLink main="true" to={`/${slug}`} current={props.match}>
                {menuItem.displayName}
              </StyledLink>
            )}
          </Match>
        )}
      </StyledLi>
    );
  });

  let navDisplay = (
    <MenuTransition2>
      <MenuNavigation>{menuItems}</MenuNavigation>
    </MenuTransition2>
  );

  let secondaryMenuItems;

  mainMenu.forEach(menuItem => {
    if (menuItem.displayName === menuFocus) {
      secondaryMenuItems = menuItem.expandedItems.map(expandedItem => {
        const slug = expandedItem.slug.current;

        return (
          <StyledLi key={expandedItem.id}>
            {!isSSR && (
              <Match path={`/${slug}`}>
                {props => (
                  <StyledLink main="true" to={`/${slug}`} current={props.match}>
                    {expandedItem.title}
                  </StyledLink>
                )}
              </Match>
            )}
          </StyledLi>
        );
      });
    }
  });

  if (menuFocus !== 'main') {
    navDisplay = (
      <MenuTransition>
        <MenuNavigation>
          <ArrowWrapper bold onClick={() => setMenuFocus('main')}>
            <LeftArrow />
            &nbsp;
            <div>{menuFocus}</div>
          </ArrowWrapper>
          {secondaryMenuItems}
        </MenuNavigation>
      </MenuTransition>
    );
  }
  return (
    <div>
      <MobileMenu menuOpen={menuOpen}>
        <MenuHeader>
          <div>
            <StyledLogoMobile to="/">
              <img
                src={imageUrlFor(buildImageObj(logo))
                  .width(100)
                  .auto('format')
                  .url()}
                alt={logo.alt}
              />
            </StyledLogoMobile>
          </div>
          <CloseMenu onClick={() => setMenuOpen(!menuOpen)}>&#x2715;</CloseMenu>
        </MenuHeader>
        {navDisplay}
      </MobileMenu>

      <MobileWrapper>
        <StyledLogo to="/">
          {!menuOpen && (
            <img
              src={imageUrlFor(buildImageObj(logoDark))
                .width(100)
                .auto('format')
                .url()}
              alt={logoDark.alt}
            />
          )}
        </StyledLogo>
        <HamburgerIcon onClick={() => setMenuOpen(!menuOpen)}>
          <Line />
          <Line />
          <Line />
        </HamburgerIcon>
      </MobileWrapper>
    </div>
  );
};

export default MobileHeader;
