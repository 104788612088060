import React from 'react';
import styled from 'styled-components';
import { above } from '../styles';

const H2 = styled.h2`
  font-size: 5.2rem;
  line-height: 1.26;

  &:not(:last-child) {
    margin-bottom: 0.75em;
  }

  @media ${above.lg} {
    font-size: 7.5rem;
    lene-height: 1.25em;
  }
`;

const SectionHeading = ({ block }) => {
  return <H2>{block.sectionHeading}</H2>;
};

export default SectionHeading;
