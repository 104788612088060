import React, { useState, useEffect } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import styled from 'styled-components';
import Image from 'gatsby-image';
import CTALink from '../elements/CTALink';
import Navigation from './Navigation';
import { above, below } from '../styles';

const FooterContainer = styled.footer`
  background-color: ${props => props.theme.color.green};
  color: ${props => props.theme.color.white};
  padding-top: 50px;
  padding-bottom: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: sticky;
  bottom: 0;
  z-index: 0;
  @media ${above.sm} {
    padding-top: 50px;
    padding-bottom: 50px;
  }
`;

const Bottom = styled.ul`
  text-align: center;
  margin-bottom: 40px;
  @media ${below.md} {
    span {
      margin-bottom: 20px;
      display: block;
    }
  }
  @media ${above.md} {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

const Address = styled.p`
  text-align: center;
  margin-bottom: 40px;
  font-size: 1.6rem;
  @media ${below.sm} {
    max-width: 213px;
    margin-left: auto;
    margin-right: auto;
  }
`;

const StyledCTALink = styled(CTALink)`
  margin-bottom: 25px;
`;
const CopyRight = styled.span`
  opacity: 0.6;
  margin-bottom: 0;
`;

const PointerWrapper = styled.div`
  cursor: pointer;
`;

const Footer = () => {
  const data = useStaticQuery(graphql`
    query FooterQuery {
      companyInfo: allSanityCompanyInfo {
        edges {
          node {
            address
            city
            postalCode
            province
            companySecondaryLogo {
              alt
              asset {
                fixed(width: 36) {
                  ...GatsbySanityImageFixed_noBase64
                }
              }
            }
          }
        }
      }
      menu: sanityMenu {
        mainMenu {
          displayName
          expandedItems {
            slug {
              current
            }
            title
          }
        }
        footerMenu {
          slug {
            current
          }
          title
        }
      }
    }
  `);
  const { node } = data.companyInfo.edges[0];
  const { companyInfo } = data;

  const smoothScroll = () => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  };

  const [innerHeight, setInnerHeight] = useState();

  useEffect(() => {
    window.addEventListener('resize', () => {
      setInnerHeight(window.innerHeight);
    });
  }, []);

  return (
    <FooterContainer>
      <PointerWrapper>
        <p onClick={() => smoothScroll()}>Top ↑</p>
      </PointerWrapper>
      {innerHeight < 400 ? (
        ''
      ) : (
        <>
          <Address>
            {`${node.address}, ${node.city}, ${node.province} ${node.postalCode}`}
          </Address>
          <Bottom>
            <CopyRight>&copy; The Jibe Multimedia, Inc.</CopyRight>
            <Navigation footer />
          </Bottom>
        </>
      )}
      <Image
        fixed={companyInfo.edges[0].node.companySecondaryLogo.asset.fixed}
      />
    </FooterContainer>
  );
};
export default Footer;
