import React from 'react';
import { Link } from 'gatsby';
import styled, { css } from 'styled-components';
import { get } from 'lodash';

import { FluidImg } from '../elements';
import { above, below } from '../styles';

const ListWrapper = styled.div`
  position: relative;
  ${props => (props.extraMarginBottom ? 'margin-bottom: 15px' : '')};
`;

const Lists = styled.ul`
  @media ${above.sm} {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;
    margin-left: auto;
  }

  @media ${above.lg} {
    ${props =>
      props.blockWithHeading &&
      css`
        padding-top: 120px;
      `};
  }

  @media ${above.xl} {
    ${props =>
      props.blockWithHeading &&
      css`
        padding-top: 155px;
      `};
  }

  &::after {
    /* width: ${props => !props.nested && '45%'};
    max-width: ${props => !props.nested && '380px'}; */
    content: '';
    @media ${above.sm} {
     width: ${props => !props.nested && '45%'};
    }

    @media ${above.md} {
      width: 45%;
    }

    @media ${above.lg} {
     width: ${props => !props.nested && '27%'};
    }
  }
`;

const Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: ${props => props.theme.color.blue.navy};
`;

const hoverStyle = css`
  span {
    background-image: linear-gradient(
      ${props => props.theme.color.green},
      ${props => props.theme.color.green}
    );
    background-position: 0% 87%;
    background-repeat: no-repeat;
    background-size: 0% 5px;
    transition: background-size 0.3s;

    &:hover {
      background-size: 100% 5px;
    }
  }
`;

const ListHeading = styled.h3`
  display: inline-block;
  font-size: 2.2rem;
  font-weight: bold;
  margin-top: 10px;
  margin-bottom: 10px;
  color: ${props => props.theme.color.blue.navy};
  &:visited {
    color: ${props => props.theme.color.blue.navy};
  }
  ${props =>
    props.as === Link &&
    css`
      ${hoverStyle}
    `};
`;

const List = styled.li`
  overflow: hidden; /* Expand hover zone for title to cover full block */
  margin-bottom: 40px;

  &:last-child {
    margin-bottom: 0px;
  }

  @media ${above.sm} {
    width: ${props => !props.nested && '45%'};
  }

  @media ${above.md} {
    margin-bottom: 40px;
    width: 47%;

    &:nth-last-child(-n + 2) {
      margin-bottom: 0;
    }
  }

  @media ${above.lg} {
    width: ${props =>
      !props.nested && props.itemsPerRowDesktop === '3' && '27%'};

    &:nth-last-child(-n + 2) {
      margin-bottom: 0;
    }
  }

  @media ${above.xl} {
    max-width: ${props => (props.nested ? '380px' : 'none')};

    &:nth-last-child(-n + 2) {
      margin-bottom: 0;
    }
  }

  ${props =>
    props.itemiconalign === 'inline' &&
    css`
      display: flex;
    `}
`;

const ListDescripton = styled.p`
  // font-size: 1.6rem;
  // line-height: 1.889;
  margin-bottom: 0;

  @media ${above.md} {
    font-size: 1.8rem;
    line-height: 1.889;
  }
`;

const ImgWrapper = styled.div`
  width: 100%;
  max-width: 50px;
  height: auto;
  margin-right: 20px;
`;

const IconTextList = ({ block, nested }) => {
  const { iconTextList, itemsPerRowDesktop, extraMarginBottom } = block;
  return (
    <ListWrapper extraMarginBottom={extraMarginBottom}>
      <Lists nested={nested} blockWithHeading={block.heading}>
        {iconTextList &&
          iconTextList.map(item => (
            <List
              itemsPerRowDesktop={itemsPerRowDesktop}
              nested={nested}
              key={item._key}
              itemiconalign={item.itemIconAlign}
            >
              {get(item, 'icon.asset') && (
                <ImgWrapper>
                  <FluidImg
                    key={item.icon._id}
                    asset={item.icon.asset}
                    alt={item.icon.alt}
                  />
                </ImgWrapper>
              )}
              <Content>
                {item.title && (
                  <ListHeading
                    as={get(item, 'titleUrl[0].slug.current') && Link}
                    to={get(item, 'titleUrl[0].slug.current')}
                    itemiconalign={item.itemIconAlign}
                  >
                    <span>{item.title}</span>
                  </ListHeading>
                )}
                {item.description && (
                  <ListDescripton>{item.description}</ListDescripton>
                )}
              </Content>
            </List>
          ))}
      </Lists>
    </ListWrapper>
  );
};

export default IconTextList;
