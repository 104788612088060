import React, { useRef, useState, useEffect } from 'react';
import styled from 'styled-components';
import { get, debounce } from 'lodash';
import { useSpring, animated } from 'react-spring';
import { FaChevronRight } from 'react-icons/fa';
import { useWindowSize } from 'react-use';

import { above, below, BREAKPOINTS } from '../styles';
import { imageUrlFor, buildImageObj } from '../utils/image-url';

const Heading = styled.h3`
  margin-top: auto;
  font-size: 3.6rem;

  @media ${below.md} {
    text-align: center;
  }
`;

const CTA = styled.a`
  display: inline-block;
  padding: 20px;
  border: 2px solid ${props => props.theme.color.blue.navy};
  text-align: center;
`;

const Content = styled.div`
  display: flex;

  @media ${above.md} {
    flex-direction: column;
    justify-content: flex-end;
    width: 75%;
  }
`;

const ImageWrapper = styled.div`
  display: none;
  @media ${above.md} {
    display: block;
    width: 20%;
  }

  img {
    width: 100%;
  }
`;

const Icon = styled.button`
  display: flex;
  color: ${props => props.theme.color.white};
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: -80px;
  width: 50px;
  height: 50px;
  border-radius: 9999px;
  border: 1px solid;
  box-shadow: ${props => props.theme.shadow.base};

  svg {
    transform: rotate(${props => (props.showPopup ? 0 : '180deg')});
  }
`;

const Wrapper = styled.aside`
  display: flex;
  justify-content: center;
  background-color: ${props =>
    props.bgcolor && get(props.theme, props.bgcolor)};
  padding: 50px;

  @media ${above.md} {
    box-shadow: ${props => props.theme.shadow.base};
  }

  ${Icon} {
    background-color: ${props =>
      props.bgcolor && get(props.theme, props.bgcolor)};
    border-color: ${props => props.bgcolor && get(props.theme, props.bgcolor)};
  }

  @media ${above.md} {
    justify-content: space-between;
    position: fixed;
    z-index: 100;
    bottom: 10%;
    right: 0;
    min-width: 650px;
    padding: 45px 0 50px 45px;
  }

  ${Heading} {
    color: ${props => {
      if (
        props.bgcolor === 'color.blue.primary' ||
        props.bgcolor === 'color.blue'
      )
        return props.theme.color.white;
    }};
  }

  ${CTA} {
    color: ${props => {
      if (
        props.bgcolor === 'color.blue.primary' ||
        props.bgcolor === 'color.blue'
      )
        return props.theme.color.white;
    }};
    border-color: ${props => {
      if (
        props.bgcolor === 'color.blue.primary' ||
        props.bgcolor === 'color.blue'
      )
        return props.theme.color.white;
    }};
  }
`;

const ContentWrap = styled.div`
  @media ${below.md} {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;

const SCROLL_THRESHOLD = 1.5;

const Popup = ({ block }) => {
  const prevScrollY = useRef(0);
  const { heading, bgColor, ctaUrl, ctaUrlText, image } = block;
  const [scrollPastThreshold, setScrollPastThreshold] = useState(false);
  const [showPopup, setShowPopup] = useState(false);

  // Use this instead of useWindowSize from react-use to avoid over-rendering
  const [isMobile, setIsMobile] = useState(false);

  const wrapperRef = useRef();

  const handleScroll = debounce(() => {
    const currentScrollY =
      window.pageYOffset !== undefined
        ? window.pageYOffset
        : (
            document.documentElement ||
            document.body.parentNode ||
            document.body
          ).scrollTop;

    // if the screen has been scrolled past threshold of the viewport height
    if (currentScrollY <= window.innerHeight * SCROLL_THRESHOLD) {
      setScrollPastThreshold(true);
    }

    prevScrollY.current = currentScrollY;
  }, 100);

  useEffect(() => {
    if (scrollPastThreshold) {
      setShowPopup(true);
      window.removeEventListener('scroll', handleScroll);
    } else {
      window.addEventListener('scroll', handleScroll);
    }

    return () => window.removeEventListener('scroll', handleScroll);
  }, [scrollPastThreshold]);

  useEffect(() => {
    function handleResize() {
      if (window.innerWidth > BREAKPOINTS.md && isMobile) {
        setIsMobile(false);
      }
      if (window.innerWidth < BREAKPOINTS.md && !isMobile) {
        setIsMobile(true);
      }
    }
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  });

  function toggleShowPopup() {
    setShowPopup(!showPopup);
  }

  const isFile = get(ctaUrl[0], 'asset.url');

  const animationProps = useSpring({
    transform: `translateX(${showPopup ? 0 : isMobile ? 0 : '100%'})`,
    from: {
      transform: `translateX(${!isMobile ? '100%' : 0})`,
    },
  });

  const iconAnimationProps = useSpring({
    opacity: scrollPastThreshold ? 1 : 0,
    transform: `translateY(${
      showPopup ? 0 : get(wrapperRef, 'current.scrollHeight', 0) - 50
    }px)`,
  });

  const AnimatedWrapper = animated(Wrapper);
  const AnimatedIcon = animated(Icon);

  return (
    <AnimatedWrapper ref={wrapperRef} style={animationProps} bgcolor={bgColor}>
      <AnimatedIcon
        showPopup={showPopup}
        style={iconAnimationProps}
        onClick={toggleShowPopup}
      >
        <FaChevronRight />
      </AnimatedIcon>
      <Content>
        <ContentWrap>
          {heading && <Heading>{heading}</Heading>}
          {ctaUrlText && ctaUrl && (
            <CTA href={isFile ? isFile : undefined} target={isFile && '_blank'}>
              {ctaUrlText}
            </CTA>
          )}
        </ContentWrap>
      </Content>
      <ImageWrapper>
        {image && (
          <img
            src={imageUrlFor(buildImageObj(image))
              .auto('format')
              .url()}
            alt={image.alt}
          />
        )}
      </ImageWrapper>
    </AnimatedWrapper>
  );
};

export default Popup;
