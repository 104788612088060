import React from 'react';
import styled from 'styled-components';

const P = styled.p`
  margin-bottom: 2em;

  &:last-child {
    margin-bottom: 0px;
  }
`;

const SectionParagraph = ({ block }) => {
  return <P>{block.sectionParagraph}</P>;
};

export default SectionParagraph;
