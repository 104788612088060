import React from 'react';
import styled from 'styled-components';
import { above, below } from '../styles';
import { Container, Paragraph, CTALink } from '../elements';
import checkPropsEquality from '../utils/checkPropsEquality';
import { imageUrlFor, buildImageObj } from '../utils/image-url';

const HeroWrapper = styled.section`
  display: flex;
  flex-wrap: wrap;
  padding-top: 200px;
  padding-bottom: 170px;
  @media ${below.md} {
    padding-bottom: 150px;
  }
`;

const ImageAndLinkWrapper = styled.div`
  margin-right: 84px;

  @media ${below.lg} {
    margin-left: 0px;
  }
`;

const ImageAndLinkWrapperSticky = styled.div`
  @media ${above.md} {
    position: sticky;
    top: 50px;
  }
`;

const ImgWrapper = styled.div`
  width: 240px;

  img {
    width: 100%;
    max-width: 100%;
  }
`;

const StyledCTALink = styled(CTALink)`
  width: 100%;
  margin-top: 40px;
  margin-bottom: 40px;
  font-weight: bold;

  @media ${above.md} {
    margin-top: 30px;
    margin-bottom: 30px;
  }

  @media ${above.lg} {
    justify-content: flex-end;
  }
`;

const StyledExternalLink = styled.a`
  display: inline-flex;
  align-items: center;
  width: 100%;
  margin-top: 40px;
  margin-bottom: 40px;
  font-weight: bold;
  color: ${props => props.theme.color.blue.navy};
  line-height: 1.38;
  font-size: 1.8rem;

  &:hover {
    &:after {
      width: 0;
    }

    svg {
      transform: translateX(5px);
      transition: transform 300ms ease;
    }
  }

  @media ${below.md} {
    font-size: 1.6rem;
  }

  @media ${above.md} {
    margin-top: 30px;
    margin-bottom: 30px;
  }

  @media ${above.lg} {
    justify-content: flex-end;
  }

  svg {
    display: inline-block;
    vertical-align: middle;
    margin-left: 12px;
    transition: transform 300ms ease;
    path {
      fill: ${props => props.theme.color.blue.navy};
    }
  }
`;

const HeadingBlock = styled.div`
  min-width: calc(100% - 330px);
  @media ${below.lg} {
    width: 100%;
    max-width: none;
  }
`;

const Meta = styled.div`
  max-width: 700px;
  @media ${below.lg} {
    width: 100%;
    max-width: none;
  }
`;

const Heading = styled.h1`
  font-weight: bold;
  font-size: 4.6rem;
  line-height: 1.25;
  max-width: 700px;
  margin-bottom: 20px;
  color: ${props => props.theme.color.blue.navy};
  @media ${above.lg} {
    font-size: 7.5rem;
  }
`;

const KeyPointsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  @media ${below.lg} {
    flex-direction: column;
  }
`;

const KeyPoint = styled.div`
  display: inline-block;
  margin-top: 30px;
  width: calc(33.333% - 20px);
  margin-right: 20px;
  &:last-child {
    margin-right: 0;
  }

  @media ${below.md} {
    width: 100%;
    margin-right: 0px;
    &:nth-child(1) {
      margin-top: 16.5px;
    }
  }
`;

const KeyPointTitle = styled.div`
  font-weight: bold;
`;

const KeyPointText = styled.div`
  margin-top: 8px;
  line-height: 1.5;
`;

const CaseStudyHero = ({
  block: {
    heroTitle,
    heroParagraph,
    heroLogo,
    expertise,
    platform,
    deliverables,
    websiteURL,
    external,
  },
}) => (
  <Container>
    <HeroWrapper>
      <ImageAndLinkWrapper>
        <ImageAndLinkWrapperSticky>
          <ImgWrapper>
            <img
              src={imageUrlFor(buildImageObj(heroLogo))
                .width(240)
                .auto('format')
                .url()}
              alt={heroLogo.alt}
            />
          </ImgWrapper>
          {external &&
            <StyledExternalLink
              href={websiteURL}
              target="_blank">
              Launch project
              <svg
                width="22"
                height="16"
                viewBox="0 0 22 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M21.7071 8.70711C22.0976 8.31658 22.0976 7.68342 21.7071 7.29289L15.3431 0.928932C14.9526 0.538408 14.3195 0.538408 13.9289 0.928932C13.5384 1.31946 13.5384 1.95262 13.9289 2.34315L19.5858 8L13.9289 13.6569C13.5384 14.0474 13.5384 14.6805 13.9289 15.0711C14.3195 15.4616 14.9526 15.4616 15.3431 15.0711L21.7071 8.70711ZM0.5 9H21V7H0.5V9Z" />
              </svg>
            </StyledExternalLink>
          }
          {!external &&
          <StyledCTALink
            url={websiteURL}
            text="Launch project"
            external={external}
            darkMode
          />
          }
        </ImageAndLinkWrapperSticky>
      </ImageAndLinkWrapper>
      <HeadingBlock>
        <Meta>
          <Heading>{heroTitle}</Heading>
          <Paragraph text={heroParagraph} />
        </Meta>
        <KeyPointsWrapper>
          <KeyPoint>
            <KeyPointTitle>Expertise</KeyPointTitle>
            <KeyPointText>{expertise}</KeyPointText>
          </KeyPoint>
          <KeyPoint>
            <KeyPointTitle>Platform</KeyPointTitle>
            <KeyPointText>{platform}</KeyPointText>
          </KeyPoint>
          <KeyPoint>
            <KeyPointTitle>Deliverables</KeyPointTitle>
            <KeyPointText>{deliverables}</KeyPointText>
          </KeyPoint>
        </KeyPointsWrapper>
      </HeadingBlock>
    </HeroWrapper>
  </Container>
);

export default React.memo(CaseStudyHero, checkPropsEquality);
