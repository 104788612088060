import React from 'react';
import styled from 'styled-components';
import getYouTubeID from 'get-youtube-id';

const YouTube = ({ block }) => {
  const { url, centered } = block;
  const id = getYouTubeID(url);
  const embedUrl = `https://www.youtube.com/embed/${id}`;

  if (!id) {
    return <div>Missing YouTube URL</div>;
  }
  const YoutubeWrapper = styled.div`
    text-align: ${props => (props.centered ? 'center' : 'none')};
    width: 100%;
    height: 0;
    position: relative;
    padding-bottom: 56.25%; /* 16:9 */
    padding-top: 25px;
  `;

  return (
    <YoutubeWrapper centered>
      <iframe
        title="YouTube Embed"
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
        }}
        src={embedUrl}
        frameBorder="0"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
      />
    </YoutubeWrapper>
  );
};

export default YouTube;
