import React from 'react';
import styled from 'styled-components';
import { above, below } from '../styles';
import { Container, FluidImg } from '../elements';
import checkPropsEquality from '../utils/checkPropsEquality';
import toTitleCase from '../utils/toTitleCase';

const Wrapper = styled.section`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 0px;
  margin-bottom: 60px;
  @media ${above.md} {
    margin-top: 80px;
    margin-bottom: 80px;
  }
`;

const List = styled.ul`
  align-items: center;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  max-width: 1024px;
  width: 60%;
  &::after {
    content: '';
    width: 30%;
  }

  @media ${below.md} {
    flex-direction: row;
    width: 100%;
  }
`;

const ListItem = styled.li`
  @media ${above.md} {
    width: 25%;
  }

  @media ${below.md} {
    width: calc(100% / ${props => props.arrayLength});
    padding-right: 3.125em;
  }

  @media ${below.sm} {
    padding-right: 1.5em;
  }
`;

const Logos = ({ block }) => {
  const { logoImageSettings, partners } = block;
  const imgSrc =
    logoImageSettings === ('white' || 'dark')
      ? 'companyLogo' + toTitleCase(logoImageSettings)
      : 'companyLogo';

  return (
    <Container>
      <Wrapper>
        <List>
          {partners &&
            partners.map(b => (
              <ListItem key={b._id} arrayLength={partners.length}>
                <FluidImg asset={b[imgSrc].asset} alt={b[imgSrc].alt} />
              </ListItem>
            ))}
        </List>
      </Wrapper>
    </Container>
  );
};

export default React.memo(Logos, checkPropsEquality);
