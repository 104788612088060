import React from 'react';
import styled from 'styled-components';
import { get } from 'lodash';

import { imageUrlFor, buildImageObj } from '../utils/image-url';

const Item = styled.li`
  display: flex;
  align-items: center;

  img {
    width: 100%;
    height: 100%;
    max-width: 150px;
    max-height: 70px;
  }
`;

const Logo = ({ logo, className }) => {
  return (
    <Item className={className} key={logo._id}>
      <img
        src={imageUrlFor(buildImageObj(logo.companyLogo))
          .width(150)
          .auto('format')
          .url()}
        alt={get(logo, 'companyLogo.alt', '')}
      />
    </Item>
  );
};

export default Logo;
