import React, { Fragment } from 'react';
import styled from 'styled-components';
import { Container, CTALink, FluidImg, Paragraph } from '../elements';
import { below, above } from '../styles';
import checkPropsEquality from '../utils/checkPropsEquality';

const Banner = styled.div`
  background-color: ${props => props.background};
  width: 100%;
  min-height: 500px;
  display: flex;
  flex-direction: column;
  justify-content: ${props => (props.imageCentered ? 'initial' : 'flex-end')};
  align-items: flex-end;

  @media ${above.md} {
    align-items: initial;
    justify-content: ${props =>
      props.imageCentered ? 'initial' : 'space-between'};
    flex-direction: row;
  }
`;

const Text = styled.div`
  padding-top: 80px;
  padding-bottom: ${props => (props.imageCentered ? '0px' : '40px')};
  height: auto;
  width: 100%;

  color: ${props =>
    props.headingWhiteText
      ? props.theme.color.white
      : props.theme.color.blue.navy};

  @media ${above.md} {
    width: 50%;
    padding-top: 140px;
    padding-bottom: 80px;
  }

  @media ${above.lg} {
    padding-top: ${props => (props.imageCentered ? '100px' : '80px')};
  }

  @media ${above.xl} {
    width: ${props => (props.imageCentered ? '55%' : '50%')};
    padding-top: ${props => (props.imageCentered ? '135px' : '80px')};
    font-size: 18px;
    line-height: 34px;
  }

  @media ${above.xxl} {
    width: 56%;
    padding-top: 90px;
    padding-top: ${props => (props.imageCentered ? '135px' : '90px')};
    padding-bottom: 90px;
`;

const ParagraphWrapper = styled.div`
  //max-width: 580px;
  margin-bottom: 25px;

  p {
    margin-bottom: ${props => (props.imageCentered ? '10px' : '40px')};
  }
`;

const CenteredImage = styled.div`
  display: flex;
  width: 100%;
  max-width: 280px;
  margin-left: calc(50% - 140px);
  margin-right: calc(50% - 140px);

  @media ${above.md} {
    width: 50%;
    max-width: 400px;
    margin-left: calc(25% - 200px);
    margin-right: calc(25% - 200px);
  }
`;

const StyledImage = styled.div`
  display: flex;
  width: 100%;

  @media ${below.md} {
    max-width: 650px;
    padding-left: 40px;
  }

  @media ${above.md} {
    width: 50%;
    max-width: 1000px;
    margin-top: -5%;
  }
`;

const HeaderMessage = styled.h4`
  font-size: 4.6rem;
  line-height: 1.26;

  @media ${above.lg} {
    font-size: 5.2rem;
  }
`;

const StyledCTALink = styled(CTALink)`
  font-size: 1.6rem;
  line-height: 2;
  font-weight: bold;
`;

const ClientBanner = ({ block }) => {
  if (!block) {
    return null;
  }
  const {
    clientRef: { brandColor, image },
    heading,
    headingWhiteText,
    imageCentered,
    message,
    cTAText,
    cTAUrl,
  } = block;

  const Component = imageCentered ? CenteredImage : StyledImage;

  return (
    <Banner
      background={brandColor && brandColor.hex}
      imageCentered={imageCentered}
    >
      <Text headingWhiteText={headingWhiteText} imageCentered={imageCentered}>
        <Container>
          {heading && <HeaderMessage>{heading}</HeaderMessage>}
          {message && (
            <ParagraphWrapper imageCentered={imageCentered}>
              <Paragraph text={message} />
            </ParagraphWrapper>
          )}
          {cTAUrl.slug && (
            <StyledCTALink
              url={'/' + cTAUrl.slug.current}
              text={cTAText}
              darkMode={!headingWhiteText}
            />
          )}
        </Container>
      </Text>
      <Component>
        <FluidImg
          asset={image && image.asset}
          alt={image && image.alt}
          maxWidth={imageCentered ? 1650 : 900}
          height="100%"
          objectFit="cover"
          objectPosition={imageCentered ? '50% 0' : '0 0'}
        />
      </Component>
    </Banner>
  );
};

export default React.memo(ClientBanner, checkPropsEquality);
