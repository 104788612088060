import React from 'react';

import styled from 'styled-components';
import { CTALink, Container } from '../elements';
import { above } from '../styles';

import BlockRenderer from './BlockRenderer';
import TwoColumnGrid from './TwoColumnGrid';

const Wrapper = styled.section`
  background-color: ${props => props.bgColor};
  margin-top: 75px;
  margin-bottom: 75px;

  @media ${above.md} {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    margin-bottom: ${props => (props.noBottomSpacing ? 0 : '175px')};
    margin-top: ${props => (props.noTopSpacing ? 0 : '175px')};
  }
`;

const Content = styled.div`
  /* IE */
  width: 100%;
`;

const LinkWrapper = styled.div``

const OneColumnGrid = ({ block }) => {
  const {
    content,
    bgColor,
    fullWidth,
    noTopSpacing,
    noBottomSpacing,
    ctaUrlText,
    ctaUrlInternal,
    ctaUrlExternal,
  } = block;

  const Component = fullWidth ? 'div' : Container;
 
  return (
    <Wrapper
      bgcolor={bgColor}
      as={Component}
      noBottomSpacing={noBottomSpacing}
      noTopSpacing={noTopSpacing}
    >
      <Content>
        {content.map(item =>
          item._type === 'twoColumnGrid' ? (
            <TwoColumnGrid nestedGrid key={item._key} block={item} />
          ) : (
            <BlockRenderer key={item._key} block={item} />
          )
        )}
        <LinkWrapper>
          {ctaUrlExternal ? (
          <CTALink
            center
            darkMode
            url={ctaUrlExternal}
            text={ctaUrlText}
                      />
          ) : ctaUrlInternal && (
          <CTALink
            darkMode
            center
            url={'/' + ctaUrlInternal[0].slug.current}
            text={ctaUrlText}
                      />
          )}
        </LinkWrapper>
      </Content>
    </Wrapper>
  );
};

export default OneColumnGrid;
