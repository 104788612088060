import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import { CTALink } from '../elements';
import { above } from '../styles';
import { imageUrlFor, buildImageObj } from '../utils/image-url';
import checkPropsEquality from '../utils/checkPropsEquality';
import TrackVisibility from 'react-on-screen';

const StyledImage = styled.div`
  display: flex;
  align-items: center;
  width: 35%;
  transition: all 1s ease;
  margin-left: ${props => (props.imagePos === 'right' ? '40px' : '-1px')};
  margin-right: ${props => (props.imagePos === 'left' ? '40px' : '-2px')};

  img {
    width: 100%;
  }
`;

const Company = styled.h5`
  transition: opacity 0.5s ease;
  font-weight: normal;
  margin-bottom: 0.7em;
  line-height: 1.33;
  font-size: 1.8rem;
  color: ${props =>
    props.headingWhiteText
      ? props.theme.color.white
      : props.theme.color.blue.navy};

  @media ${above.sm} {
    font-size: 2.4rem;
  }
`;

const Container = styled.div`
  position: relative;
  background-color: ${props => props.background};
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: ${props => props.imagePos === 'left' && ' row-reverse'};
  overflow: hidden;

  &::before {
    content: '';
    width: 1px;
    margin-left: -1px;
    float: left;
    height: 0;
    padding-top: 100%;
  }

  &::after {
    /* to clear float */
    content: '';
    display: table;
    clear: both;
  }

  &:hover {
    ${StyledImage} {
      margin-left: ${props => (props.imagePos === 'right' ? '60px' : '-21px')};
      margin-right: ${props => (props.imagePos === 'left' ? '60px' : '-22px')};
    }
    ${Company} {
      opacity: 0.4;
    }
  }
`;

const Text = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: ${props =>
    props.headingPos === 'top' ? 'flex-start' : 'flex-end'};
  z-index: 20;
  width: 60%;

  padding-top: 40px;
  padding-bottom: 40px;
  padding-left: ${props => props.imagePos === 'right' && '20px'};
  padding-right: ${props => props.imagePos === 'left' && '20px'};

  @media ${above.sm} {
    padding-top: 50px;
    padding-bottom: 50px;
    padding-left: ${props => props.imagePos === 'right' && '40px'};
    padding-right: ${props => props.imagePos === 'left' && '40px'};
  }
  @media ${above.md} {
    width: 65%;
    padding-top: 65px;
    padding-bottom: 65px;
    padding-left: ${props => props.imagePos === 'right' && '65px'};
    padding-right: ${props => props.imagePos === 'left' && '65px'};
  }
`;

const Message = styled.h4`
  font-size: 2.4rem;
  line-height: 1.8;
  margin-top: 0 !important;
  color: ${props =>
    props.headingWhiteText
      ? props.theme.color.white
      : props.theme.color.blue.navy};
  @media ${above.sm} {
    font-size: 3.6rem;
  }
`;

const StyledLink = styled(CTALink)`
  &::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
  }
`;

const VisibilityContent = styled.div``;

const VisibilityContainer = styled.div`
  line-height: 0;
  max-height: 572px;
  max-width: 572px;
  @media ${above.xxl} {
    max-height: 700px;
    max-width: 700px;
  }
  transition-property: clip-path transform box-shadow;
  transition-duration: 0.5s;
  transition-timing-function: ease;
  transition-delay: ${props => (props.isVisible ? '0s' : '0.5s')};
  overflow: hidden;
  background-color: ${props => props.background};
  transform: scale(${props => (props.isHover ? '1.02' : '1.0')});
  box-shadow: 0 0 60px 0px
    ${props => (props.isHover ? 'rgba(32,40,70,0.3)' : 'rgba(32,40,70,0)')};
  clip-path: polygon(
    -20% -40%,
    ${props => (props.isVisible ? '140%' : '0%')} 0,
    ${props => (props.isVisible ? '120%' : '0%')} 120%,
    -20% 120%
  );
  ${VisibilityContent} {
    transition-property: opacity;
    transition-duration: 0.5s;
    transition-timing-function: ease;
    transition-delay: ${props => (props.isVisible ? '0.5s' : '0')};
    opacity: ${props => (props.isVisible ? '1' : '0')};
  }
`;

const ClientCard = ({ card, className }) => {
  const {
    clientRef: { brandColor, name },
    cardImage,
    heading,
    headingPos,
    headingWhiteText,
    imagePos,
    cTAText,
    cTaUrl,
  } = card;

  const [isHover, setIsHover] = useState(false);
  const handleMouseEnter = () => {
    setIsHover(true);
  };

  const handleMouseLeave = () => {
    setIsHover(false);
  };

  return (
    <TrackVisibility
      //once='true'
      offset={300}
    >
      <VisibilityContainer
        background={brandColor.hex}
        isHover={isHover ? 1 : 0}
      >
        <VisibilityContent>
          <Container
            className={className}
            background={brandColor.hex}
            imagePos={imagePos}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            isHover={isHover ? 1 : 0}
          >
            <Text headingPos={headingPos} imagePos={imagePos}>
              <Company headingWhiteText={headingWhiteText}>{name}</Company>
              <Message headingWhiteText={headingWhiteText}>{heading}</Message>

              {cTaUrl.slug && (
                <StyledLink
                  url={'/' + cTaUrl.slug.current}
                  text={cTAText}
                  darkMode={!headingWhiteText}
                />
              )}
            </Text>
            <StyledImage imagePos={imagePos}>
              <img
                src={imageUrlFor(buildImageObj(cardImage))
                  .width(500)
                  .auto('format')
                  .url()}
                alt={cardImage.alt}
              />
            </StyledImage>
          </Container>
        </VisibilityContent>
      </VisibilityContainer>
    </TrackVisibility>
  );
};

export default React.memo(ClientCard, checkPropsEquality);
